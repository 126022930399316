import React, { FC, useState } from "react";
import { Card, Row } from "react-bootstrap";
import CardHeader from "react-bootstrap/CardHeader";
import { getLabelName } from "../../utils";
import { Form, Formik, FormikProps } from "formik";
import {
  LookupItemModel,
  ValidationErrorModel,
  RuleExpRecipeAccountsEnum,
  ruleExpenseMoneySearchModel,
} from "../../models";
import { TextBox, ButtonBox, ErrorValidationBox } from "..";
import {
  saveRuleExpenseMoney,
  saveRuleReceiveMoney,
} from "../../serviceBroker/accountApiServiceBroker";

export const RegisterRuleExpenseMoney: FC<{
  searchPanelHeader: string;
  acTransactionType: RuleExpRecipeAccountsEnum;
  acTransaction: ruleExpenseMoneySearchModel;
  setLoading: Function;
  customerAccountLookups: LookupItemModel[];
  currencyLookUps: LookupItemModel[];
  coastCenterLookUps: LookupItemModel[];
  suppliersAccountLookups: LookupItemModel[];
  handleSaveEmployeeComplete: Function;
}> = (props) => {
  //#region variables
  const {
    searchPanelHeader,
    acTransactionType,
    acTransaction,
    setLoading,
    handleSaveEmployeeComplete,
  } = props;
  //#endregion
  //#region state
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [validationSchema] = useState();
  //#endregion
  //#region functions
  const handleSubmit = async (request: ruleExpenseMoneySearchModel) => {
    try {
      setLoading(true);
      if (request.ID === 0) request.rowState = 1;
      else {
        request.rowState = 2;
      }
      let res;
      if (acTransactionType == RuleExpRecipeAccountsEnum.Expense)
        res = await saveRuleExpenseMoney(request);
      else res = await saveRuleReceiveMoney(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        handleSaveEmployeeComplete(true);
        setLoading(false);
      } else {
        setValidationErrors([]);
        handleSaveEmployeeComplete(true);
        setLoading(false);
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region html
  const renderChild: FC<FormikProps<ruleExpenseMoneySearchModel>> = ({
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    handleReset,
    values,
  }) => {
    return (
      <>
        {<ErrorValidationBox errors={validationErrors} />}
        <Form onSubmit={handleSubmit}>
          <Row>
            <TextBox
              inputName={"ArabicName"}
              labelName="ArabicName"
              errorText={errors.ArabicName}
              inputValue={values.ArabicName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Row>

          <TextBox
            inputName={"Notes"}
            labelName="Notes"
            errorText={errors.Notes}
            type="textarea"
            inputValue={values.Notes}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <div className="accordion-footer">
            <div className="col-md-12 d-flex justify-content-end">
              <ButtonBox
                type="submit"
                className="btn btn-orange"
              >
                {getLabelName("Save")}
              </ButtonBox>
              <ButtonBox
                variant="outline-primary"
                type="button"
                className="btn btn-orange"
                onClick={handleReset}
              >
                {getLabelName("Close")}
              </ButtonBox>
            </div>
          </div>
        </Form>
      </>
    );
  };
  return (
    <>
      <Card>
        <CardHeader>{getLabelName(searchPanelHeader)}</CardHeader>
        <Card.Body>
          <Formik
            initialValues={acTransaction}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              await handleSubmit(values);
            }}
            onReset={() => {
              handleSaveEmployeeComplete(false);
            }}
            children={(formik) => renderChild(formik)}
          />
        </Card.Body>
      </Card>
    </>
  );
  //#endregion
};
