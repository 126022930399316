import {RoutePageConfigurations} from "../routeConfigurations/routePageConfig";

export const SystemConfiguration = {
    apiEndPoint: {
        alyusrApiEndpoint: process.env.REACT_APP_AlyusrApiEndpoint,
    },
    keys: {
        menu: "=XQ#whTQ",
        menuItemName: "u8W9c",
        flattenMenuItem:"0j2s2twxsb",
        privileges: "VmGp*@QQ",
        dictionary: "PtrPwthR",
        appConfig: "8yELE",
        token: "cjJ1k4zPcy",
        user: "WquUJGSg",
        companySettings: "8t5Mmn6p6V",
        culture: "RdNnfa#h",
        homePageReloaded: "zN3ti",
        defaultUiLanguage: "ar-AE",
        sidebarIsOpen:"rdzjajyky3"
    },
    cacheKey: {
        settingsList: "Tf1Si3LlCp",
        posSettingsList: "et2KHSCjpm",
        userPosSettings: "c226hz1Stp",
        storeTransferSettingList:"y10pib9h6l",
        layoutName: "JHroyuJRsg"
    },
    lruCacheConfiguration:{
        maxCount:100,
        maxAge:36000000, //10 hour   -->1000 milliseconds (1 second)* 60 seconds (1 minute)*60 minutes (1 hour)
    },
    cookie: {
        expiryMinutes: 240,
        encryptKey: "cookieSecretKey",
    },
    indexDb: {
        indexDbName: "bcqeOt1X56Db",
        // itemObject:"ft7dzThMU8Obj"
    },
    security: {
        encryptCryptoJSKey: "SZGlFNgutW",
    },
    other: {
        domainName: process.env.REACT_APP_DomainName,
        logoutRedirectUrl: `${RoutePageConfigurations.logoutRedirectPage}?iOZQilU=LyKiRiQEPDibKuuGECyJ`,
    },
};
