import { useFormik } from "formik";
import * as Yup from "yup";
import React, { FC, useState } from "react";
import {
  RequestActionModel,
  AccrualSubtractItemModel,
  ValidationErrorModel,
  ActionTypeEnum,
  RowStateEnum,
  HasFormIdModel,
} from "../../models";
import { ErrorValidationBox, TextBox, LoadingBox, ButtonBox } from "..";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";
import { SaveAccrualSubtractRule } from "../../serviceBroker/employeeEstihkakSubtracApiServiceBroker";
import { PrivilegesChecker } from "..";

interface AddDueSubtractRuleProps extends HasFormIdModel {
  request: AccrualSubtractItemModel;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AddDueSubtractRule: FC<AddDueSubtractRuleProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const initialValues: AccrualSubtractItemModel = request;
  //#endregion
  //#region state
  const isArabic = isArabicCurrentLanguage();
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const validationSchema = Yup.object({
    Name: Yup.string().required(getLabelName("required")),
  });

  //#endregion
  //#region function
  const handleSubmit = async (request: AccrualSubtractItemModel) => {
    try {
      setLoading(true);
      request.rowState =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      const res = await SaveAccrualSubtractRule(request);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        onActionEvent({
          id: 0,
          action: ActionTypeEnum.Failed,
          request: isArabic
            ? res.Errors[0].MessageAr
            : res?.Errors[0].MessageEn,
        });
        setLoading(false);
      } else {
        setValidationErrors([]);
        setLoading(false);
        onActionEvent({ id: 0, action: ActionTypeEnum.AddSuccess });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      await handleSubmit(values);
      resetForm();
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-2 row-cols-xxl-2 row-cols-xl-2 row-cols-lg-2 row-cols-md-2 row-cols-sm-2 g-sm-2 g-md-4 align-items-start">
          <TextBox
            labelName={getLabelName("Name")}
            inputName="Name"
            isMandatory={true}
            inputValue={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Name}
          />

          <TextBox
            labelName={getLabelName("Eng Name")}
            inputName="Name_En"
            inputValue={formik.values.Name_En}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Name_En}
          />

          <TextBox
            labelName={getLabelName("Notes")}
            inputName="Notes"
            inputValue={formik.values.Notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Notes}
            type="textarea"
          />
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-end mb-3">
            <PrivilegesChecker formId={formId} action={"EnableSave"}>
              <ButtonBox
                iconType="content-save"
                type="submit"
                className="btn-primary btn-sm mx-3"
              >
                {getLabelName("Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="receipt"
              variant="danger"
              type="button"
              className="btn-sm"
              onClick={() => {
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
