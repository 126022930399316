import { Dispatch, FC, SetStateAction, useState } from "react";
import { TableColumn } from "react-data-table-component";
import { ButtonBox, LoadingBox, GridTable, TextBox,PrivilegesChecker } from "..";
import {
  ActionTypeEnum,
  SearchCustomerResponseModel,
  SearchCustomerRequestModel,
  RequestActionModel,
  SearchCustomersTypeEnum,
  SupplierCustomerTypeEnum,
  HasFormIdModel,
} from "../../models";
import {checkActionPrivilege, getLabelName} from "../../utils";

interface CustomerList extends HasFormIdModel {
  getCustomerList: (searchModel: SearchCustomerRequestModel) => void;
  type: SupplierCustomerTypeEnum;
  request: SearchCustomerResponseModel[];
  onActionEvent: (o: RequestActionModel) => void;
  onCompleteEvent?: any | null;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  totalRows: number;
}

export const CustomerList: FC<CustomerList> = ({
  request,
  type = SupplierCustomerTypeEnum.Customer,
  onActionEvent = () => {},
  getCustomerList = () => {},
  totalRows,
  formId,
}) => {
  //#region variables
  const defaultPageSize: number = 50;
  const columns: TableColumn<SearchCustomerResponseModel>[] = [
    {
      name: "#",
      selector: (_row, index) => Number(index || 0) + 1,
      sortable: true,
    },
    {
      name: getLabelName("Code"),
      selector: (row) => row.Code,
      sortable: true,
      wrap: true,
    },
    {
      name: getLabelName("Name"),
      selector: (row) => row.Name,
      sortable: true,
      wrap: true,
    },
    {
      name: getLabelName("English Name"),
      selector: (row) => row.Name_En,
      sortable: true,
      wrap: true,
      grow: 8,
    },
    {
      name: getLabelName("Address"),
      selector: (row) => row.Address,
      sortable: true,
      wrap: true,
    },
    {
      name: getLabelName("Mobile"),
      selector: (row) => row.Mobile,
      sortable: true,
    },
    {
      name: getLabelName("ID"),
      selector: (row) => row.IDNumber,
      sortable: true,
    },
    {
      button: true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: any) => (
          checkActionPrivilege({action: "EnableUpdate", formId: formId}) && (
              <ButtonBox
                  className="btn-primary btn btn-xs"
                  onClick={() => {
                    onActionEvent({
                      id: row.ID,
                      request: row,
                      action: ActionTypeEnum.Update,
                    });
                  }}
              >
                {getLabelName("Edit")}
              </ButtonBox>
          )
      ),
    },
    {
      button: true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: any) => (
          checkActionPrivilege({action: "EnableDelete", formId: formId}) && (
              <ButtonBox
                  className="btn-danger btn btn-xs"
                  onClick={() => {
                    onActionEvent({
                      id: row.ID,
                      request: row,
                      action: ActionTypeEnum.Delete,
                    });
                  }}
              >
                {getLabelName("Delete")}
              </ButtonBox>
          )
      ),
    },
  ];
  //#endregion
  //#region state
  const [searchValue, setSearchValue] = useState("");
  const [searchCustomerRequest, setSearchCustomerRequest] =
    useState<SearchCustomerRequestModel>({
      pageNumber: 1,
      type: type,
      pageSize: defaultPageSize,
      searchType: SearchCustomersTypeEnum.CustomerName,
      searchValue: searchValue,
    });
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region functions
  const handleSearch = async () => {
    setLoading(true);
    getCustomerList(searchCustomerRequest);
    setLoading(false);
  };
  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchCustomerRequest.pageNumber) {
      const obj = { ...searchCustomerRequest };
      obj.pageNumber = pageNumber;
      setSearchCustomerRequest(obj);
      searchCustomerRequest.pageNumber = pageNumber;
    }
    getCustomerList(searchCustomerRequest);
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchCustomerRequest.pageSize) {
      const obj = { ...searchCustomerRequest };
      obj.pageSize = pageSize;
      setSearchCustomerRequest(obj);
      searchCustomerRequest.pageSize = pageSize;
    }
    getCustomerList(searchCustomerRequest);
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <PrivilegesChecker formId={formId} action="EnableSearch">
        {" "}
        <div
          style={{ maxWidth: "50%" }}
          className="col-12 d-flex justify-content-start mt-4"
        >
          <TextBox
            labelName={getLabelName("")} //{getLabelName("lookup.nameAr")}
            inputName={"filterTextBox"}
            inputValue={""}
            onChange={(e: any) => {
              setSearchCustomerRequest({
                ...searchCustomerRequest,
                searchValue: e.target.value,
              });
              setSearchValue(e.target.value);
            }}
          />

          <ButtonBox
            type="submit"
            className="btn-gradient-primary mx-3"
            variant=""
            onClick={async () => {
              await handleSearch();
            }}
          >
            {getLabelName("Search")}
          </ButtonBox>
        </div>
      </PrivilegesChecker>

      <GridTable
        columns={columns}
        data={request}
        totalRows={totalRows}
        currentPage={searchCustomerRequest.pageNumber || 1}
        pageSize={searchCustomerRequest.pageSize || defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationType="server"
      />
    </>
  );
  //#endregion
};
