import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { initialFormValues } from "./state/state";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AccountAcBeginBalanceTypeEnum,
  AccountResponseResultModel,
  PageEnum,
  RowStateEnum,
  SelectItemModel,
  ToastModel,
  ValidationErrorModel,
} from "../../models";
import {
  TextBox,
  RadioCheckBox,
  SelectBox,
  ErrorValidationBox,
  ButtonBox,
  PrivilegesChecker,
} from "..";
import { getLabelName } from "../../utils";
import { Checkbox } from "antd";
import { useStoredAccountTypes } from "../../hooks";
import { saveAccount } from "../../serviceBroker/accountApiServiceBroker";

export interface AccountsFormProps {
  currentAccountInfo: AccountResponseResultModel;
  resetCurrentAccountInfo: () => void;
  currentParentID: number | null;
  switchToSavedAccount: (
    account: AccountResponseResultModel,
    isNew: boolean
  ) => void;
  setIsFormSubmitting: (isSubmitting: boolean) => void;
  setToastModel: Dispatch<SetStateAction<ToastModel>>;
}

const validationSchema = Yup.object({
  Code: Yup.string(),
  ArabicName: Yup.string().required(getLabelName("required")),
  EnglishName: Yup.string(),
  NatureType: Yup.string().required(getLabelName("required")),
  AccountType_ID: Yup.number().required(getLabelName("required")),
  IsShowInCostCenter: Yup.boolean(),
  Orders: Yup.number(),
  Notes: Yup.string(),
  Account: Yup.string(),
  Balance: Yup.number(),
});

const AccountsForm: React.FC<AccountsFormProps> = ({
  currentAccountInfo,
  switchToSavedAccount,
  setIsFormSubmitting,
  setToastModel,
  currentParentID,
  resetCurrentAccountInfo,
}) => {
  //#region states
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const { accountTypesLoading, storedAccountTypes } = useStoredAccountTypes();
  const isAddingAccount = currentAccountInfo.ID === null;
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (currentParentID === null)
        return setValidationErrors([
          {
            MessageAr: getLabelName("Parent Account Not Selected"),
            MessageEn: "Parent Account Not Selected",
          },
        ]);
      setIsFormSubmitting(true);
      setValidationErrors([]);
      const result = await saveAccount({
        ...currentAccountInfo,
        ...values,
        Parent_ID: isAddingAccount
          ? currentParentID
          : currentAccountInfo.Parent_ID,
        rowState: isAddingAccount ? RowStateEnum.Add : RowStateEnum.Update,
      });
      if (result.Errors && result.Errors?.length !== 0)
        setValidationErrors(result.Errors);
      else {
        resetCurrentAccountInfo();
        switchToSavedAccount(
          result.Result as AccountResponseResultModel,
          isAddingAccount
        );
        formik.resetForm();
        setToastModel({ show: true });
      }
      setIsFormSubmitting(false);
    },
    validateOnChange: false,
    validateOnBlur: false,
  });
  //#endregion
  //#region refs
  const accountTypesRef = useRef<HTMLElement | null>(null);
  const TopForm = useRef<HTMLFormElement | null>(null);
  //#endregion
  //#region handlers
  const handleEditAccount = () => {
    const {
      Code,
      ArabicName,
      EnglishName,
      NatureType,
      AccountType_ID,
      IsShowInCostCenter,
      Orders,
      Notes,
      Balance,
    } = currentAccountInfo;
    formik.setErrors({});
    formik.setFieldValue("Code", Code);
    formik.setFieldValue("ArabicName", ArabicName);
    formik.setFieldValue("EnglishName", EnglishName);
    formik.setFieldValue("NatureType", NatureType);
    formik.setFieldValue("AccountType_ID", AccountType_ID);
    formik.setFieldValue("IsShowInCostCenter", IsShowInCostCenter);
    formik.setFieldValue("Orders", Orders);
    formik.setFieldValue("Notes", Notes);
    formik.setFieldValue("Balance", Balance);
  };
  //#endregion
  //#region UseEffect
  useEffect(() => {
    if (TopForm.current) {
      TopForm.current.scrollIntoView({ behavior: "smooth" });
    }
    setValidationErrors([]);
    handleEditAccount();
  }, [currentAccountInfo]);
  //#endregion
  //#region html
  return (
    <>
      {<ErrorValidationBox errors={validationErrors} />}
      <form
        ref={TopForm}
        className="forms-sample"
        onSubmit={formik.handleSubmit}
      >
        <div className=" row row-cols-1 row-cols-xxl-3 row-cols-xl-2 row-cols-lg-1 row-cols-md-1 row-cols-sm-1 g-md-4 g-sm-4">
          <TextBox
            key="Code"
            labelName="Code"
            inputName="Code"
            placeHolder="Code"
            inputValue={formik.values.Code}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Code}
          />

          <TextBox
            key="ArabicName"
            labelName="Arabic Name"
            placeHolder="Arabic Name"
            inputName="ArabicName"
            isMandatory={true}
            inputValue={formik.values.ArabicName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.ArabicName}
          />

          <TextBox
            key="EnglishName"
            labelName="EnglishName"
            placeHolder="EnglishName"
            inputName="EnglishName"
            inputValue={formik.values.EnglishName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.EnglishName}
          />
          <div>
            <RadioCheckBox
              labelName={getLabelName("NatureTypeType")}
              isDisabled={!isAddingAccount}
              items={[
                {
                  text: getLabelName("Credit"),
                  value: "" + AccountAcBeginBalanceTypeEnum.CreditAccount,
                  name: "NatureType",
                },
                {
                  text: getLabelName("Debit"),
                  value: "" + AccountAcBeginBalanceTypeEnum.DebitAccount,
                  name: "NatureType",
                },
              ]}
              onChange={formik.handleChange}
              type={"radio"}
              selectedValues={["" + formik.values.NatureType]}
            />
          </div>
          <SelectBox
            labelName={getLabelName("account type")}
            inputName="account type"
            selectedValues={
              formik.values.AccountType_ID === null
                ? null
                : ["" + formik.values.AccountType_ID]
            }
            source={storedAccountTypes}
            onStatusChange={(selectedValue: SelectItemModel) =>
              formik.setFieldValue("AccountType_ID", selectedValue.value)
            }
            isSingleSelect={true}
            multiselectRef={accountTypesRef}
            errorText={getLabelName(formik.errors.AccountType_ID as string)}
            isDataLoading={accountTypesLoading}
          />
          <div className="d-flex justify-content-center align-items-center">
            <Checkbox
              checked={formik.values.IsShowInCostCenter}
              name="IsShowInCostCenter"
              onChange={() => {
                formik.setFieldValue(
                  "IsShowInCostCenter",
                  !formik.values.IsShowInCostCenter
                );
              }}
              id="IsShowInCostCenter"
            />
            <label
              style={{ marginInlineStart: "8px" }}
              htmlFor="costCenterVisible"
            >
              {getLabelName("Show In Cost Center")}
            </label>
          </div>

          <TextBox
            key="Orders"
            labelName="Orders"
            placeHolder="Orders"
            inputName="Orders"
            inputValue={formik.values.Orders}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Orders}
            type={"number"}
          />

          <TextBox
            labelName="Account"
            inputName={"Account"}
            inputValue={""}
            isReadOnly={true}
          />

          <TextBox
            key="Balance"
            labelName="Balance"
            placeHolder="Balance"
            inputName="Balance"
            inputValue={formik.values.Balance}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Balance}
            type={"number"}
            isReadOnly={true}
          />
        </div>
        <div className="row mt-4">
          <TextBox
            key="Notes"
            labelName="Notes"
            placeHolder="Notes"
            inputName="Notes"
            inputValue={formik.values.Notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            errorText={formik.errors.Notes}
            type={"textarea"}
          />
        </div>

        <div className="row mt-4">
          <div className="col-12 d-flex justify-content-end">
            <PrivilegesChecker
              formId={PageEnum.AccountsTree}
              action="EnableSave"
            >
              <ButtonBox iconType="content-save" type="submit" className="mx-3">
                {getLabelName("Save")}
              </ButtonBox>
            </PrivilegesChecker>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
export default AccountsForm;
