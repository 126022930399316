import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { TableColumn } from "react-data-table-component";
import { ButtonBox, GridTable, LoadingBox, TextBox } from "..";
import {
  AcBeginBalanceTransactionDetailModel,
  AccountAcBeginBalanceTypeEnum,
  ActionTypeEnum,
  HasFormIdModel,
  RequestActionModel,
  RowStateEnum,
} from "../../models";
import { checkActionPrivilege, getLabelName } from "../../utils";
import { getAcTransactionBeginBalanceList } from "../../serviceBroker/acTransactionsApiServiceBroker";
import { PrivilegesChecker } from "..";

interface AcBeginBalanceListProps extends HasFormIdModel {
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AcBeginBalanceList: FC<AcBeginBalanceListProps> = ({
  setIsRefresh,
  isRefresh,
  onActionEvent = () => {},
  formId,
}) => {
  //#region state
  const [beginBalanceList, setBeginBalanceList] = useState<
    AcBeginBalanceTransactionDetailModel[]
  >([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region variables
  const columns: TableColumn<AcBeginBalanceTransactionDetailModel>[] = [
    {
      name: "#",
      selector: (_row, index) => Number(index || 0) + 1,
      sortable: true,
    },
    {
      name: getLabelName("To Account"),
      selector: (row) => row.AccountNameAr,
      sortable: true,
      wrap: true,
      allowOverflow: true,
    },
    {
      name: getLabelName("Total Debit"),
      id: "total_debit_column",
      cell: (row: AcBeginBalanceTransactionDetailModel) => (
        <TextBox
          labelName=""
          inputName="DebitValue"
          inputValue={row.DebitValue}
          type="number"
          isReadOnly={
            row.Type === Number(AccountAcBeginBalanceTypeEnum.CreditAccount)
          }
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            updateState({
              ...row,
              DebitValue: val || 0,
              accountNatureType: row.Type,
              rowState: Number(RowStateEnum.Update),
            });
          }}
        ></TextBox>
      ),
      //sortable: true,
      wrap: true,
      allowOverflow: true,
    },
    {
      name: getLabelName("Total Credit"),
      id: "total_credit_column",
      cell: (row: AcBeginBalanceTransactionDetailModel) => (
        <TextBox
          labelName=""
          inputName="CreditValue"
          inputValue={row.CreditValue}
          isReadOnly={
            row.Type !== Number(AccountAcBeginBalanceTypeEnum.CreditAccount)
          }
          onChange={async (e: any) => {
            const val = e.target.value === null ? 0 : Number(e.target.value);
            updateState({
              ...row,
              CreditValue: val || 0,
              accountNatureType: row.Type,
              rowState: Number(RowStateEnum.Update),
            });
          }}
        ></TextBox>
      ),
      sortable: true,
      //sortFunction: caseInsensitiveSort,
      //wrap: true,
      allowOverflow: true,
    },
    {
      button: true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: AcBeginBalanceTransactionDetailModel) =>
        checkActionPrivilege({ action: "EnableDelete", formId: formId }) && (
          <ButtonBox
            iconType="delete"
            id="deleteBalanceButton"
            variant="danger"
            className="btn-xs"
            onClick={() => {
              onActionEvent({
                id: row.ID,
                request: row,
                action: ActionTypeEnum.Delete,
              });
            }}
          >
            {getLabelName("Delete")}
          </ButtonBox>
        ),
    },
  ];
  //#endregion
  //#region functions
  const getAllObjects = async () => {
    setLoading(true);
    const result = await getAcTransactionBeginBalanceList();
    setBeginBalanceList(result);
    setLoading(false);
  };
  const updateState = (request: AcBeginBalanceTransactionDetailModel) => {
    setBeginBalanceList((prevList) =>
      prevList.map((row) => (row.ID === request.ID ? request : row))
    );
  };
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      await getAllObjects();
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (isRefresh) {
        await getAllObjects();
        setIsRefresh(false);
      }
    };
    fillData().then(() => {});
  }, [isRefresh]);
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {beginBalanceList !== null && beginBalanceList.length !== 0 && (
        <>
          <GridTable
            columns={columns}
            data={beginBalanceList}
            totalRows={beginBalanceList.length}
            currentPage={1}
            pageSize={10000}
            paginationType="client"
          />
          {beginBalanceList.filter(
            (p) => p.rowState == Number(RowStateEnum.Update)
          ).length > 0 && (
            <div className="w-100 mt-3 d-flex justify-content-end">
              {/* <PrivilegesChecker formId={formId} action="EnableSave"> */}
              <ButtonBox
                iconType="content-save"
                type="button"
                className="btn btn-sm btn-gradient-primary me-2"
                onClick={async () => {
                  onActionEvent({
                    action: ActionTypeEnum.BulkUpdate,
                    request: beginBalanceList.filter(
                      (p) => p.rowState == Number(RowStateEnum.Update)
                    ),
                  });
                }}
              >
                {getLabelName("Save")}
              </ButtonBox>
              {/* </PrivilegesChecker> */}
              <ButtonBox
                variant="danger"
                iconType="receipt"
                type="button"
                className="btn-fw"
                onClick={async () => {
                  await getAllObjects();
                }}
              >
                {getLabelName("New")}
              </ButtonBox>
            </div>
          )}
        </>
      )}
    </>
  );
  //#endregion
};
