import { FC, useMemo } from "react";
import {
  AccrualSubtractItemModel,
  HasFormIdModel,
  ActionTypeEnum,
  EmployeeEstihkakSubtractResponseModel,
} from "../../models";
import {checkActionPrivilege, getLabelName} from "../../utils";
import { TableColumn } from "react-data-table-component";
import { ButtonBox, GridTable } from "..";

interface DueSubtractRulesListProps extends HasFormIdModel {
  request: AccrualSubtractItemModel[];
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const DueSubtractRulesList: FC<DueSubtractRulesListProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region state
  const columns: TableColumn<EmployeeEstihkakSubtractResponseModel>[] = useMemo(
    () => [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        sortable: true,
        width: "100px",
      },

      {
        name: getLabelName("Name"),
        selector: (row) => row.Name,
        sortable: true,
        wrap: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Name English"),
        selector: (row) => row.Name_En,
        sortable: true,
        wrap: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Notes"),
        selector: (row) => row.Notes,
        sortable: true,
        allowOverflow: true,
      },

      {
          button:true,
          // eslint-disable-next-line react/button-has-type
        cell: (row: any) => (
            checkActionPrivilege({action:"EnableUpdate" ,formId:formId}) && (
            <ButtonBox
              iconType="pencil-box"
              className="btn btn-primary btn-xs"
              onClick={() => {
                onActionEvent({
                  id: row.ID,
                  request: row,
                  action: ActionTypeEnum.Update,
                });
              }}
            >
              {getLabelName("Edit")}
            </ButtonBox>
            )
        ),
      },
      {
          button:true,
          // eslint-disable-next-line react/button-has-type
        cell: (row: any) => (
              checkActionPrivilege({action:"EnableDelete" ,formId:formId}) && (
            <ButtonBox
              iconType="delete"
              className="btn btn-danger btn-xs"
              onClick={() => {
                onActionEvent({
                  id: row.ID,
                  request: row,
                  action: ActionTypeEnum.Delete,
                });
              }}
            >
              {getLabelName("Delete")}
            </ButtonBox>
              )
        ),
      },
    ],
    []
  );
  //#endregion
  //#region html
  return (
    <>
      <GridTable
        columns={columns}
        data={request}
        totalRows={request.length}
        currentPage={1}
        pageSize={50}
        paginationType="client"
      />
    </>
  );
  //#endregion
};
