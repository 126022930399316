import { useFormik } from "formik";
import * as Yup from "yup";
import { FC, useEffect, useState } from "react";
import {
  RequestActionModel,
  ValidationErrorModel,
  ActionTypeEnum,
  RowStateEnum,
  ResponseBaseModel,
  LookupItemModel,
  LookupTypeEnum,
  paymentTypesApiResponseModel,
} from "../../models";
import {
  TextBox,
  LoadingBox,
  SelectBox,
  ButtonBox,
  PrivilegesChecker,
} from "..";
import { getLabelName, getLookUpItemNumericValue } from "../../utils";
import { savePaymentType } from "../../serviceBroker/paymentTypeApiServiceBroker";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";

export const AddPaymentType: FC<{
  request: paymentTypesApiResponseModel | null;
  onActionEvent: (o: RequestActionModel) => void;
  formId: number;
}> = ({ request, onActionEvent, formId }) => {
  //#region variables
  const initialValues: paymentTypesApiResponseModel = {
    EnglishName: "",
    IsEnable: true,
    Parent_ID: 1,
    Broker_Percentage: 0,
    Is_Reserved: false,
    Account_ID: 0,
    ID: 0,
    CreatedBy: "",
    ModifiedBy: "",
    Name: "",
    CreationDate: new Date(),
    ModificationDate: new Date(),
    VerifyOnUpdate: false,
    rowState: Number(RowStateEnum.Add),
  };
  //#endregion
  //#region state
  const [validationSchema] = useState(
    Yup.object({
      Name: Yup.string().required(getLabelName("required")),
    })
  );
  const [loading, setLoading] = useState(false);
  const [paymentTypeList, setPaymentTypeList] = useState<LookupItemModel[]>([]);
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    //enableReinitialize: true,
    onReset: () => {
      // resetForm();
    },
    onSubmit: async (values) => {
      await handleSubmit(values);
      //resetForm();
    },
  });
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      const paymentType = await getLookupByType(LookupTypeEnum.PaymentType);
      const formikValues: paymentTypesApiResponseModel =
        request || initialValues;
      formikValues.Parent_ID =
        formikValues.Parent_ID === 0 || formikValues.Parent_ID === 1
          ? Number(paymentType[0].value)
          : formikValues.Parent_ID;
      await formik.setValues(formikValues);
      setPaymentTypeList(paymentType);
      setLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (request != null) {
        setLoading(true);
        await formik.setValues(request);
        setLoading(false);
      }
    };
    fillData().then(() => {});
  }, [request]);
  //#endregion
  //#region function
  const handleSubmit = async (request: paymentTypesApiResponseModel) => {
    try {
      setLoading(true);
      request.rowState =
        request.ID === 0
          ? Number(RowStateEnum.Add)
          : Number(RowStateEnum.Update);
      const res: ResponseBaseModel<paymentTypesApiResponseModel> =
        await savePaymentType(request);
      if (res != null && res.Errors != null && res.Errors.length > 0) {
        setLoading(false);
        onActionEvent({
          request: res.Errors,
          action: ActionTypeEnum.Failed,
        });
      } else {
        setLoading(false);
        formik.resetForm();
        onActionEvent({
          action: ActionTypeEnum.AddSuccess,
        });
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      onActionEvent({
        request: errors,
        action: ActionTypeEnum.Failed,
      });
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <form className="forms-sample" onSubmit={formik.handleSubmit}>
        <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-1 g-sm-2 g-md-4 align-items-start">
          <TextBox
            labelName={getLabelName("Arabic Name")}
            inputName={"Name"}
            errorText={formik.errors.Name}
            inputValue={formik.values.Name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextBox
            labelName={getLabelName("English Name")}
            inputName={"EnglishName"}
            errorText={formik.errors.EnglishName}
            inputValue={formik.values.EnglishName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <SelectBox
            labelName={getLabelName("Price")}
            source={paymentTypeList}
            isSingleSelect={true}
            errorText={formik.errors.Parent_ID}
            onStatusChange={async (e: any) => {
              const value = getLookUpItemNumericValue(e);
              if (value !== null) {
                formik.values.Parent_ID = value;
                await formik.setFieldValue("Parent_ID", value);
              }
            }}
            selectedValues={[formik.values.Parent_ID.toString()]}
            multiselectRef={undefined}
          />
          <TextBox
            type="checkbox"
            labelName={getLabelName("Is Enable")}
            inputName={getLabelName("is Enable")}
            errorText={formik.errors.IsEnable}
            inputValue={formik.values.IsEnable}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </div>
        <div className="row py-2">
          <div className="col-12 d-flex justify-content-end">
            <PrivilegesChecker formId={formId} action="EnableSave">
              <ButtonBox
                type="submit"
                className="btn-gradient-primary mx-3 btn-fw"
              >
                {getLabelName("Save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              variant="danger"
              type="button"
              className="btn-fw"
              iconType="receipt"
              onClick={(e) => {
                formik.handleReset(e);
                onActionEvent({ id: 0, action: ActionTypeEnum.Clear });
              }}
            >
              {getLabelName("New")}
            </ButtonBox>
          </div>
        </div>
      </form>
    </>
  );
  //#endregion
};
