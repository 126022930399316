import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {checkActionPrivilege, getLabelName, isArabicCurrentLanguage} from "../../utils";
import {
  ButtonBox,
  LoadingBox,
    GridTable,
  TextBox,
} from "..";
import {
    GenericSearchRequestModel,
    RequestActionModel,
    ActionTypeEnum,
    paymentTypesApiResponseModel, PageEnum,
} from "../../models";
import { TableColumn } from "react-data-table-component";
import { getAllPaymentTypes } from "../../serviceBroker/paymentTypeApiServiceBroker";

export const PaymentTypeList: FC<{
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  onActionEvent: (o: RequestActionModel) => void;
}> = ({ setIsRefresh, isRefresh, onActionEvent }) => {
  //#region variables
  const isArabic: boolean = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const columns: TableColumn<paymentTypesApiResponseModel>[] = useMemo(
    () => [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        width: "80px",
      },
      {
        name: getLabelName("Name_Ar"),
        selector: (row) => row.Name,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("English Name"),
        selector: (row) => row.EnglishName,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("isEnable"),
        cell: (row: paymentTypesApiResponseModel) => (
          <TextBox
            type="checkbox"
            labelName={""}
            inputName={getLabelName("isEnable")}
            inputValue={row?.IsEnable}
            isReadOnly={true}
          />
        ),
      },
      {
          button:true,
          // eslint-disable-next-line react/button-has-type
        cell: (row: paymentTypesApiResponseModel) => (
            checkActionPrivilege({action:"EnableUpdate" ,formId:PageEnum.PaymentType}) && (
            <ButtonBox
              id="modifyItemBtn"
              className="btn-xs"
              onClick={() => {
                onActionEvent({
                  id: row.ID,
                  request: row,
                  action: ActionTypeEnum.Update,
                });
              }}
            >
              {getLabelName("Edit")}
            </ButtonBox>
            )
        ),
      },
      {
          button:true,
          // eslint-disable-next-line react/button-has-type
        cell: (row: paymentTypesApiResponseModel) => (
            checkActionPrivilege({action:"EnableDelete" ,formId:PageEnum.PaymentType}) && (
            <ButtonBox
              id="deleteItemBtn"
              variant="danger"
              className="btn-xs"
              onClick={() => {
                onActionEvent({
                  id: row.ID,
                  request: row,
                  action: ActionTypeEnum.Delete,
                });
              }}
            >
              {getLabelName("Delete")}
            </ButtonBox>
            )
        ),
      },
    ],
    [isArabic]
  );
  const [searchParams] = useState<GenericSearchRequestModel>({
    pageSize: 10,
    pageNumber: 1,
  });
  const [data, setData] = useState<paymentTypesApiResponseModel[]>([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      setData(await getList());
      setLoading(false);
      setIsRefresh(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (isRefresh) {
        setLoading(true);
        setData(await getList());
        setLoading(false);
        setIsRefresh(false);
      }
    };
    fillData().then(() => {});
  }, [isRefresh]);
  //#endregion
  //#region functions
  const getList = async (): Promise<paymentTypesApiResponseModel[]> => {
    const paymentTypesList = await getAllPaymentTypes();
    return paymentTypesList !== null &&
      paymentTypesList !== undefined &&
      paymentTypesList.Result !== null &&
      paymentTypesList.Result !== undefined &&
      paymentTypesList.Result?.length !== 0
      ? paymentTypesList.Result
      : [];
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <GridTable
        columns={columns}
        data={data}
        totalRows={data.length}
        currentPage={searchParams.pageNumber}
        pageSize={searchParams.pageSize}
        // onCurrentPageChange={onCurrentPageChange}
        // onPageSizeChange={onPageSizeChange}
        paginationType="client"
      />
    </>
  );
  //#endregion
};
