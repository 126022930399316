import {TableColumn} from "react-data-table-component";
import {ActionTypeEnum, EmployeeListItemModel} from "../../../models";
import {checkActionPrivilege, getLabelName} from "../../../utils";
import {ButtonBox} from "../../index";

interface EmployeeHandler {
    onActionEvent?: any | null;
}

const setEmployeeColumns: (
    handlers: EmployeeHandler,
    formId: number
) => TableColumn<EmployeeListItemModel>[] = ({onActionEvent}, formId) => {
    const employeeColumns: TableColumn<EmployeeListItemModel>[] = [
        {
            name: getLabelName("name"),
            selector: (row) => getLabelName(row.Name),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Eng Name"),
            selector: (row) => getLabelName(row.NameEn),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Job"),
            selector: (row) => getLabelName(row.Job),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Address"),
            selector: (row) => getLabelName(row.Address),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Percentage Sales"),
            selector: (row) => getLabelName("" + row.Percentage_Of_Sales),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            name: getLabelName("Mobile"),
            selector: (row) => getLabelName(row.Mobile),
            sortable: true,
            wrap: true,
            allowOverflow: true,
        },
        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row) => (
                checkActionPrivilege({action:"EnableUpdate" ,formId:formId}) && (
                    <ButtonBox
                    iconType="pencil-box"
                        type="button"
                        variant="primary"
                        className="mx-2 btn-xs"
                        onClick={() => {
                            onActionEvent({
                                id: row.ID,
                                action: ActionTypeEnum.Update,
                            });
                        }}
                    >
                        {getLabelName("Edit")}
                    </ButtonBox>
                )
            ),
        },
        {
            button:true,
        // eslint-disable-next-line react/button-has-type
            cell: (row) => (
                checkActionPrivilege({action:"EnableUpdate" ,formId:formId}) && (
                    <ButtonBox
                    iconType="cash-multiple"
                        className="btn-xs"
                        type="button"
                        variant="success"
                        onClick={() => {
                            //onSelect({ id: row.ID, type: "update" });
                            onActionEvent({
                                id: row.ID,
                                action: ActionTypeEnum.OpenModal,
                            });
                        }}
                    >
                        {getLabelName("Commission Value")}
                    </ButtonBox>
                )
            ),
        },
        {
            button:true,
            // eslint-disable-next-line react/button-has-type
            cell: (row) => (
                checkActionPrivilege({action:"EnableDelete" ,formId:formId}) && (
                    <ButtonBox
                    iconType="delete"
                        className="btn-xs"
                        type="button"
                        variant="danger"
                        onClick={() => {
                            //onSelect({ id: row.ID, type: "update" });
                            onActionEvent({
                                id: row.ID,
                                action: ActionTypeEnum.Delete,
                            });
                        }}
                    >
                        {getLabelName("delete")}
                    </ButtonBox>
                )
            ),
        },
    ];

    return employeeColumns;
};

export default setEmployeeColumns;
