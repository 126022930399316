import React, { FC } from "react";
import {
  ActionTypeEnum,
  HasFormIdModel,
  CurrencyResponseModel,
} from "../../models";
import {checkActionPrivilege, getLabelName, getPagePrivileges, isArabicCurrentLanguage} from "../../utils";
import {ButtonBox, GridTable} from "..";
import {TableColumn} from "react-data-table-component";

interface CurrencyListProps extends HasFormIdModel {
  request: CurrencyResponseModel[];
  isModifyAble?: boolean | null;
  isDeleteAble?: boolean | null;
  isPermissionAble?: boolean | null;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const CurrencyList: FC<CurrencyListProps> = ({
  request,
  onActionEvent,
  formId,
}) => {
  //#region variable
  const isArabic: boolean = isArabicCurrentLanguage();
  const columns:TableColumn<CurrencyResponseModel>[] = [
    {
      name: "#",
      selector: (_row, index) => Number(index || 0) + 1,
      width: "50px",
    },
    {
      name: getLabelName("NameAr"),
      selector: (row) => row.ArabicName,
      sortable: true,
    },
    {
      name: getLabelName("NameEn"),
      selector: (row) => row.EnglishName,
      sortable: true,
    },
    {
      name: getLabelName("ShortCutArab"),
      selector: (row) => isArabic ? row.ShortCutArab : row.ShortCutArab,
      sortable: true,
    },
    {
      name: getLabelName("Value"),
      selector: (row) => row.Value,
      sortable: true,
    },
    {
      id: "update_column",
      button: true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: CurrencyResponseModel) =>
          getPagePrivileges().EnableUpdate && checkActionPrivilege({action: "EnableUpdate", formId: formId}) && (
              // <PrivilegesChecker formId={FormId} action="EnableUpdate">
              <ButtonBox
                  onClick={async () => {
                    onActionEvent({
                      id: row.ID,
                      request: row,
                      action: ActionTypeEnum.Update,
                    });
                  }}
              >
                {getLabelName("Edit")}
              </ButtonBox>
          )
    },
    {
      id: "delete_column",
      button: true,
      // eslint-disable-next-line react/button-has-type
      cell: (row: CurrencyResponseModel) =>
          getPagePrivileges().EnableDelete && checkActionPrivilege({action: "EnableDelete", formId: formId}) && (
              // <PrivilegesChecker formId={FormId} action="EnableUpdate">
              <ButtonBox
                  variant="danger"
                  className="btn-xs"
                  onClick={async () => {
                    onActionEvent({
                      id: row.ID,
                      request: row,
                      action: ActionTypeEnum.Delete,
                    });
                  }}
              >
                {getLabelName("Delete")}
              </ButtonBox>
          )
    },
  ]
  //#endregion
  //#region html
  return (
    <>
      {request != null && request.length !== 0 && (
          <GridTable
              columns={columns}
              data={request}
              totalRows={10000}
              currentPage={ 1}
              pageSize={1000}
              paginationType="none"
              //style={ TableActionColumnsStyleEnum.Dropdown}
              // subHeaderComponent={getPagePrivileges().search && subHeaderComponent}
          />
      )}
    </>
  );
  //#endregion
};
