import { FC, useState, useEffect } from "react";
import { Accordion, Col, Row } from "react-bootstrap";
import {
    ButtonBox,
    LoadingBox,
    ToastBox,
    PrivilegesChecker,
    ErrorValidationBox,
} from "..";
import {
    CategoryResponseModel,
    HasFormIdModel,
    RequestActionModel,
    ToastModel,
    ValidationErrorModel,
} from "../../models";
import { updateTaxPercentageOfCategory } from "../../serviceBroker/itemApiServiceBroker";
import { getLabelName, isArabicCurrentLanguage } from "../../utils";

interface CategoryListTaxProps extends HasFormIdModel {
    request: CategoryResponseModel[];
    onActionEvent: (o: RequestActionModel) => void;
    onCompleteEvent?: any | null;
}

export const CategoryTaxList: FC<CategoryListTaxProps> = ({
                                                              request,
                                                              formId,
                                                          }) => {
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    //#endregion
    //#region state
    const [loading, setLoading] = useState(false);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [checked, setChecked] = useState<number[]>([]);
    const [selectAll, setSelectAll] = useState(false);
    const [toastModel, setToastModel] = useState<ToastModel>({
        show: false,
    });
    const [validationErrors, setValidationErrors] = useState<
        ValidationErrorModel[]
    >([]);
    //#endregion
    //#region useEffect
    useEffect(() => {
        setSelectAll(request.length > 0 && checked.length === request.length);
    }, [checked, request]);
    //#endregion
    //#region functions
    const handleAllChecked = (event: any) => {
        if (event.target.checked) {
            const normalizedArray = request.map((obj) => obj.ID);
            setChecked(normalizedArray);
        } else {
            setChecked([]);
        }
    };

    const handleCheck = (event: any) => {
        const updatedList = [...checked];
        if (event.target.checked) {
            updatedList.push(parseInt(event.target.value));
            setChecked(updatedList);
        } else {
            const list = updatedList.filter((id) => id !== parseInt(event.target.value));
            setChecked(list);
        }
    };

    const isChecked = (item: number) =>
        checked.includes(item) ? "checked-item" : "not-checked-item";

    const handleSubmit = async () => {
        setValidationErrors([]);
        if (taxPercentage > 100 || taxPercentage < 0) {
            setValidationErrors([{ MessageAr: getLabelName("Invalid Tax"), MessageEn: getLabelName("Invalid Tax") }]);
            return;
        }
        if (checked.length === 0) {
            setValidationErrors([{ MessageAr: getLabelName("Select Category"), MessageEn: getLabelName("Select Category") }]);
            return;
        } else {
            const response = await updateTaxPercentageOfCategory({
                categoryIdsList: checked,
                TaxPercentage: taxPercentage,
                ModifcationDate: new Date(),
                ModifiedBy: 0,
            });
            setChecked([]);
            if (response.Errors != null && response.Errors.length !== 0) {
                setValidationErrors(response.Errors);
            } else {
                setToastModel({ show: true });
            }
        }
    };
    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox />}
            {<ErrorValidationBox errors={validationErrors} />}

            {toastModel.show && (
                <ToastBox
                    isShown={toastModel.show}
                    header={toastModel.Header}
                    body={toastModel.body}
                    variant={toastModel.variant}
                    delayDuration={toastModel.delayDuration}
                    onCloseEvent={() => {
                        setToastModel({ show: false });
                    }}
                />
            )}
            <div className="row">
                {loading && <LoadingBox />}
                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{getLabelName("UpdateTaxCategory")}</Accordion.Header>
                        <Accordion.Body>
                            <Row justify="start">
                                <div className="box d-flex align-items-center justify-content-start mb-4">
                                    <input
                                        type="checkbox"
                                        onChange={handleAllChecked}
                                        checked={selectAll}
                                        id="selectAll"
                                    />
                                    <label className="mx-3" htmlFor="selectAll">
                                        {getLabelName("selectAll")}
                                    </label>
                                </div>
                            </Row>
                            <div className="row row-cols-1 row-cols-xxl-4 row-cols-xl-4 row-cols-lg-4 row-cols-md-4 row-cols-sm-1 g-sm-2 g-md-4 align-items-start mb-3">
                                {request !== null &&
                                    request.length !== 0 &&
                                    request.map((row: any, index: number) => {
                                        return (
                                            <div className="col-sm-3" key={index}>
                                                <div className="container">
                                                    <input
                                                        value={row.ID}
                                                        type="checkbox"
                                                        checked={checked.includes(row.ID)}
                                                        onChange={handleCheck}
                                                        id={row.ID.toString()}
                                                        className="mx-3"
                                                    />
                                                    <label htmlFor={row.ID.toString()} className={isChecked(row.ID)}>
                                                        {isArabic ? row.Name : row.Name_En}
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                            <Row>
                                <Col xs={2}>
                                    <label>{getLabelName("TaxPercentage")}</label>
                                </Col>
                                <Col xs={3}>
                                    <input
                                        min={0}
                                        max={100}
                                        name="TaxPercentage"
                                        type="number"
                                        onChange={(e: any) => {
                                            setTaxPercentage(e.target.value);
                                        }}
                                        value={taxPercentage}
                                        className="form-control"
                                    />
                                </Col>
                            </Row>
                            <PrivilegesChecker formId={formId} action="EnableSave">
                                <div className="col-md-2 mt-3">
                                    <ButtonBox onClick={handleSubmit} iconType="content-save">
                                        {getLabelName("save")}
                                    </ButtonBox>
                                </div>
                            </PrivilegesChecker>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
    //#endregion
};
