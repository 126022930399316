import { Input} from "antd";
import React, {FC, useEffect, useState} from "react";
import {ButtonBox, GridTable, LoadingBox, TextBox} from "..";
import {
    ActionTypeEnum,
    DictionaryResponseModel,
    DictionaryModel,
    RequestActionModel,
    ResponseBaseModel,
    SearchDictionaryRequestModel,
    RowStateEnum,
} from "../../models";
import {
    getAllDictionaries,
    saveDictionaries,
} from "../../serviceBroker/dictionaryApiServiceBroker";
import { getLabelName, isArabicCurrentLanguage} from "../../utils";
import {PrivilegesChecker} from "..";
import {TableColumn} from "react-data-table-component";

export const DictionaryList: FC<{
    getDictionaries: (searchObj: SearchDictionaryRequestModel) => void;
    request: DictionaryResponseModel[];
    onActionEvent: (o: RequestActionModel) => void;
    totalRows: number;
}> = ({
          request,
          onActionEvent = () => {},
      }) => {
    //#region state
    let [dictionaries, setDictionaries] = useState<DictionaryResponseModel[]>();
    const [searchKeyWord, setSearchKeyWord] = useState<string>("");
    const [_, setSearchDictionaryRequest] =
        useState<SearchDictionaryRequestModel>({
            pageNumber: 1,
            pageSize: 50,
            searchKeyWord: "",
            lang: isArabicCurrentLanguage() ? 1 : 2,
        });
    const [loading, setLoading] = useState(false);
    //#endregion
    //#region variables
    const isArabic = isArabicCurrentLanguage();
    const columns:TableColumn<DictionaryResponseModel>[] = [
        {
            name: "#",
            selector: (_row, index) => Number(index || 0) + 1,
            width: "70px",
        },
        {
            name: getLabelName("Control"),
            selector: (_row) => _row.Name,
            sortable: true,
        },
        {
            name: getLabelName("Arabic Name"),
            // eslint-disable-next-line react/button-has-type
            cell: (row: DictionaryResponseModel) =>
                <Input
                    value={row.ArabicName}
                    onChange={(e: any) => {
                        if (dictionaries) {
                            const updatedArray = dictionaries.map((obj) => {
                                if (obj.ID === row.ID) {
                                    return {
                                        ...obj,
                                        ArabicName: e.target.value,
                                        isChanged: true,
                                    };
                                }
                                return obj;
                            });

                            setDictionaries(updatedArray);
                        }
                    }}
                    type="text"
                />
        },
        {
            name: getLabelName("English Name"),
            // eslint-disable-next-line react/button-has-type
            cell: (row: DictionaryResponseModel) =>
                <Input
                    value={row.EnglishName}
                    onChange={(e: any) => {
                        if (dictionaries) {
                            const updatedArray = dictionaries.map((obj) => {
                                if (obj.ID === row.ID) {
                                    return {
                                        ...obj,
                                        EnglishName: e.target.value,
                                        isChanged: true,
                                    };
                                }
                                return obj;
                            });

                            setDictionaries(updatedArray);
                        }
                    }}
                    type="text"
                />
        },
    ];
    //#endregion
    //#region functions
    const handleSearch = () => {
        const searchColumns = ["ArabicName", "EnglishName"];
        const result: DictionaryResponseModel[] = [];
        request?.forEach((item: any) => {
            for (let key in item) {
                if (searchColumns?.includes(key)) {
                    if (
                        item[key]
                            .toString()
                            .toLowerCase()
                            .includes(searchKeyWord.trim().toLowerCase())
                    ) {
                        result?.push(item);
                        break;
                    }
                }
            }
        });
        setDictionaries(result);
        setSearchDictionaryRequest((prevObject: any) => ({
            ...prevObject,
            searchKeyWord: searchKeyWord,
        }));
    };
    const handleSave = async () => {
        setLoading(true);
        let arr: any = [];
        dictionaries?.map((e) => {
            arr.push(e);
        });
        if (dictionaries && dictionaries.length > 0) {
            dictionaries = dictionaries.filter((x) => x.isChanged);
            const res: ResponseBaseModel<DictionaryModel> | null = await saveDictionaries(
                dictionaries
            );
            if (res != null && res.Errors != null && res.Errors.length !== 0) {
                onActionEvent({
                    id: 0,
                    action: ActionTypeEnum.Failed,
                    request: res.Errors ,
                });
                setLoading(false);
            } else {
                onActionEvent({
                    id: 0,
                    action: ActionTypeEnum.Success,
                    request: res?.Result,
                });
                let result = await getAllDictionaries();
                let response: DictionaryResponseModel[] = [];

                if (result) {
                    result.map((row, index) => {
                        response.push({
                            ArabicName: row.NameAr,
                            EnglishName: row.NameEn,
                            Name: row.Name,
                            ID: row.Id,
                            CreatedBy: 0,
                            ModifiedBy: 0,
                            rowState: RowStateEnum.Update,
                            ControlType_ID: undefined,
                            CreationDate: undefined,
                            ModificationDate: undefined,
                            ShowToUser: undefined,
                            System_ID: undefined,
                            TranslatedName: undefined,
                            VerifyOnUpdate: undefined,
                            isChanged: false,
                            index: index,
                        });
                    });
                    request = response;
                }
                handleSearch();
                setLoading(false);
            }
        }
    };
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setDictionaries(request);
        };
        fillData().then(() => {
        });
    }, [request]);

    //#endregion
    //#region html
    return (
        <>
            {loading && <LoadingBox/>}
            <div
                className="row row-cols-3 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-3 row-cols-sm-3 g-sm-3 g-md-4 align-items-start">
                <TextBox
                    labelName={""}
                    inputName={""}
                    inputValue={searchKeyWord}
                    onChange={(e: any) => {
                        setSearchKeyWord(e.target.value);
                    }}
                />
                <PrivilegesChecker formId={54} action="EnableSearch">
                    <ButtonBox style={{width: "10%"}} iconType="magnify" onClick={handleSearch}>
                        {getLabelName("Search")}
                    </ButtonBox>
                </PrivilegesChecker>
            </div>
            {dictionaries != null && dictionaries.length !== 0 && (
                <GridTable
                    columns={columns}
                    data={dictionaries}
                    totalRows={dictionaries?.length??0}
                    currentPage={ 1}
                    pageSize={100}
                    paginationType="client"
                    //style={ TableActionColumnsStyleEnum.Dropdown}
                    // subHeaderComponent={getPagePrivileges().search && subHeaderComponent}
                />
            )}
            {/*<Form>*/}
            {/*    <Table dataSource={dictionaries} columns={columns}/>*/}
            {/*</Form>*/}
            <PrivilegesChecker formId={54} action="EnableSave">
                <ButtonBox
                iconType="content-save"
                    className="btn btn-primary btn-xs"
                    disabled={!dictionaries || dictionaries.length == 0}
                    onClick={handleSave}
                    style={{margin: "10px"}}
                >
                    {getLabelName("Save")}
                </ButtonBox>
            </PrivilegesChecker>
        </>
    );
    //#endregion
};
