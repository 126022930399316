import { useEffect, useState } from "react";
import {
  SearchReservedTransactionResponseModel,
  SearchTransactionsParams,
  TransactionDetailResponseModel,
} from "../../../../../models";
import { searchReservedTransactions } from "../../../../../serviceBroker/transactionApiServiceBroker";

const initialValues: SearchTransactionsParams = {
  customerId: { value: null, active: false, label: "" },
  fromDate: { value: new Date().toISOString(), active: false },
  toDate: { value: new Date().toISOString(), active: true },
  employeeId: { value: null, active: false, label: "" },
  itemInstoreId: { value: null, active: false, label: "" },
  searchBy: { value: null, active: true, word: "" },
  pageSize: { value: 10, active: true },
  pageNumber: { value: 1, active: true },
};

const useOrdersSearch = () => {
  const [searchParams, setSearchParams] =
    useState<SearchTransactionsParams>(initialValues);
  const [searchedTransactions, setSearchedTransactions] = useState<
    SearchReservedTransactionResponseModel[]
  >([]);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (searchParams === null) return;
    const fetchTransactions = async () => {
      setIsLoading(true);
      const searchedTransactions = await searchReservedTransactions(
        searchParams
      );
      setIsLoading(false);
      if (searchedTransactions) {
        setSearchedTransactions(searchedTransactions.result);
        setTotalCount(searchedTransactions.totalCount || 0);
      }
    };
    fetchTransactions();
  }, [searchParams]);

  return {
    searchParams,
    setSearchParams,
    searchedTransactions,
    setSearchedTransactions,
    initialValues,
    isLoading,
    totalCount,
  };
};

export default useOrdersSearch;
