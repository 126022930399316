import { SearchTransactionsParams } from "../../models";

export function createQueryString(params: {[key in string]: any}): string {
  const queryParams = new URLSearchParams(params as any).toString();
  return queryParams;
}

export const filterSearchParams = (params: SearchTransactionsParams) => {
  const searchParamsMap: { [key in string]?: string | number | Date | null } =
    {};
  Object.keys(params)
    .filter((key) => {
      const param = params[key as keyof SearchTransactionsParams];
      return param.value !== null && param.active;
    })
    .forEach((key: string) => {
      searchParamsMap[key] =
        params[key as keyof SearchTransactionsParams].value;
    });
    if(params.searchBy.value !== null ){
      searchParamsMap[searchParamsMap.searchBy as string]= params.searchBy.word;
      delete searchParamsMap.searchBy;
    }
  return {...searchParamsMap};
};