import {getLabelName, getUser, getUserBranchId, getUserCurrentBranchId, SecureLocalStorageSet} from "../../utils";
import {LookupItemModel, LookupTypeEnum, UserResponseModel, ValidationErrorModel} from "../../models";
import {ButtonBox, ErrorValidationBox, LoadingBox, SelectBox} from "..";
import React, {useEffect, useState} from "react";
import {getLookupByType} from "../../serviceBroker/lookupApiServiceBroker";
import {RoutePageConfigurations, SystemConfiguration} from "../../configuration";
import {useNavigate} from "react-router-dom";

export const UserCurrentBranch = () => {
    //#region state
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [branchId, setBranchId] = useState<number | null>(getUserBranchId);
    const [branchList, setBranchList] = useState<LookupItemModel[]>([]);
    const [validationErrors, setValidationErrors] = useState<ValidationErrorModel[]>([]);
    //#endregion
    //#region useEffect
    useEffect(() => {
        const fillData = async () => {
            setLoading(true);
            if (getUserCurrentBranchId() === null) {
                const branches = await getLookupByType(LookupTypeEnum.UserBranchPermissions, true, false);
                 setBranchList(branches);
            }
            setLoading(false);
        };
        fillData().then(() => {
        });
    }, []);
    //#endregion
    //#region function
    const updateUserDefaultBranch = () => {
        setLoading(true);
        setValidationErrors([]);
        let user: UserResponseModel | null = getUser();
        if (user !== null && branchId !== null && branchId !== undefined) {
            user.currentBranchId = branchId;
            SecureLocalStorageSet(
                SystemConfiguration.keys.user,
                JSON.stringify(user)
            );
            navigate(RoutePageConfigurations.dashboardPage);
        } else {
            setValidationErrors([
                {MessageAr: getLabelName("select default branch"), MessageEn: getLabelName("select default branch")}
            ]);
        }
        setLoading(false);
    }
    //#endregion
    //#region html
    return (<>
        {loading && <LoadingBox />}
        {<ErrorValidationBox errors={validationErrors} />}
        <div className="d-flex align-items-center auth center-box">
            <div className="row flex-grow">
                <div className="col-lg-4 mx-auto">
                    <div className="auth-form-light text-left p-5">
                        <SelectBox
                            labelName={getLabelName("Branch")}
                            isSingleSelect={true}
                            selectedValues={
                                branchId !== null ?
                                    [branchId?.toString()]
                                    : null
                            }
                            source={branchList}
                            onStatusChange={(e: LookupItemModel) => {
                                setBranchId(e === null || e.value === null ? null : Number(e.value))
                            }}
                        />
                        <div className="d-flex mt-3 justify-content-end"><ButtonBox
                        iconType="check-circle"
                            type="submit"
                            variant="primary"
                            className="btn btn-orange"
                            onClick={updateUserDefaultBranch}
                        >
                            {getLabelName("Ok")}
                        </ButtonBox>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
    //#endregion
}