export const AlYuserApiUrls = {
    accountController: {
        getAccountsByMainAccountType: "/Accounts/GetAccountsByMainAccountType",
        getAllMainAccountType: "/Accounts/GetAllMainAccountType",
        getCurrentAccountBalance: "GetCurrenctAccountBalance",
        getAllBoxAccounts: "Accounts/GetAllBoxAccount",
        getAllAccounts: "GetAllAccounts",
        getAccountInfoById: "GetAccountInfoById",
        searchRuleReceiveMoneyAccounts: "SearchRuleReceiveMoneyAccounts",
        searchAccounts: "SearchAccounts",
        getAccountsToBindByAcTransactionType: "Accounts/GetAccountsToBindByAcTransactionType",
        searchRuleExpenseMoneyAccounts: "SearchRuleExpenseMoneyAccounts",
        saveAccount: "Account/SaveAccount",
        deleteAccount: "DeleteAccount",
        saveRuleExpenseMoney: "SaveRuleExpenseMoney",
        saveRuleReceiveMoney: "SaveRuleReceiveMoney",
    },
    acTransactionReportController: {
        searchReportGeneralLedger: "AcReport/GeneralLedgerReport",
        searchReportIncomeStatement: "AcReport/IncomeStatmentReport",
        searchReportBalanceSheet: "AcReport/BalanceSheetReport"
    },
    acTransactionController: {
        getAcTransactionBeginBalanceList: "AcTrasnaction/GetAcTrasnactionDetailBeginBalance",
        searchAcTransactionTreasury: "SearchAcTransactionTreasury",
        selectAcTransaction: "GetAcTrnsactionByID",
        searchAccountTransaction: "/AcTrasnaction/SearcAcTransactions",
        getAcTransactionFullDetailById: "AcTrasnaction/GetAcTransactionFullDetailById",
        getAcDailyBoxDetailsById: "AcTrasnaction/GetAcDailyBoxFullDetailById",
        printAcJurnalVoucher: "AcTrasnaction/PrintAcJurnalVoucher",
        printAcDailyBox: "AcTrasnaction/PrintAcDailyBox",
        printAcTransactionTreasury: "PrintAcTransactionTreasury",
        saveBeginBalanceAcTransaction: "AcTrasnaction/SaveSimpleAcTransaction",
        deleteAcTransaction: "DeleteAcTransaction",
        saveCurrentVoucher: "/AcTrasnaction/SaveJurnalVoucher",
        saveCurrentDailyBox: "/AcTrasnaction/SaveDailyBox",
    },
    alertReportController: {
        getReportOfUserAlert: "AlertReport/GetReportOfUserAlert",
    },
    branchController: {
        getAllBranches: "Branche/GetAllBranches",
        getBranchById: "Branch/GetBranchById",
        saveBranch: "Branch/SaveBranch",
        deleteBranch: "Branch/deleteBranch"
    },
    calcTypeController: {
        getAllCalcTypes: "CalcType/GetAllCalacType",
        getCalcTypeById: "CalcType/GetCalcTypeById",
        saveCalcType: "CalcType/SaveCalcType",
        deleteCalcType: "CalcType/DeleteCalcType"
    },
    categoryController: {
        getCategoryList: "GetCategoryList",
        GetCategoryById: "Category/GetCategoryById",
        getCategoryImageById: "Category/GetCategoryImageById",
        saveCategory: "SaveCategory",
        deleteCategory: "DeleteCategory",
    },
    commonController:{
        getControlsSettings:"GetControlsSettings",
        saveGvColumnControlSettingList:"GvColumnsControlSetting/SaveGvColumnControlSettingList"
    },
    customerController: {
        searchCustomLight: "Customers/SearchCustomLight",
        searchCustomer: "SearchCustomer",
        getCustomerInvoiceType: "GetCustomerInvoiceType",
        getAllCustomers: "GetAllCustomers",
        getAllSuppliers: "GetAllSuppliers",
        deleteCustomer: "DeleteCustomer",
        saveCustomer: "SaveCustomer"
    },
    customerReportController: {
        reportDebitCustomer: "CustomerReport/ReportDebitCustomer",
        reportDebitSupplier: "CustomerReport/ReportDebitSupplier",
        reportCustomersTransactionAndAcTransaction: "CustomerReport/ReportCustomersTransactionAndAcTransaqction",
        reportSuppliersTransactionAndAcTransaction: "CustomerReport/ReportSuppliersTransactionAndAcTransaqction",
        reportCustomersData: "CustomerReport/ReportCustomersData",
        reportCustomerPayments: "CustomerReport/ReportCustomerPayments",
        reportCustomersAccount: "CustomerReport/ReportCustomersAccount",
        reportAllCustomersTransaction: "CustomerReport/ReportAllCustomersTransaction"
    },
    currencyController: {
        getCurrencyList: "GetCurrencyList",
        getCurrencyById: "GetCurrencyById",
        getCurrenciesShortCut: "GetCurrenciesShourtCut",
        saveCurrency: "SaveCurrency",
        deleteCurrency: "DeleteCurrency"
    },
    companySettingController: {
        getCompanySetting: "getCompanySettings",
        getActiveVouchersSettings: "GetActiveVouchersSettings",
        getEgyptInvoiceSettings: "GetEgypteInvoiceSettings",
        saveCompanySetting: "SaveCompanySetting",
        saveVoucherSettings: "SaveVoucherSettings",
        saveEgyptInvoiceSettings: "SaveEgypteInvoiceSettings"
    },
    closeDayController: {
        searchStation: "Stations/SearchStation",
        closeDayOfStation: "Stations/CloseDayOfStation",
        printCloseDay: "Station/PrintCloseDay"
    },
    dictionaryController: {
        getAllDictionaryList: "GetAllDictionaryList",
        getButtonDictionaryList: "GetButtonDictionaryList",
        saveNotExistTranslation: "SaveNotExistTranslation",
        saveDictionaries: "SaveDictionaries",
    },
    employeeController: {
        getAllEmployees: "GetAllEmployees",
        getEmployeeInformation: "GetEmployeeById",
        getEmployeeCommissionPerCategory: "Employee/GetEmployeeCommissionPerCategory",
    },
    employeeEstihkakSubtractController: {
        getAllEsthkakSubtractByRuleTypeId: "GetAllEsthkakSubtractByRuleTypeId",
        getEstihkakSubtractRuleById: "GetEstihkakSubtractRuleById",
        GetEstihkakSubtractById: "GetEstihkakSubtractById",
        searchEstihkakSubtract: "SearchEstihkakSubtract",
        saveEstihkakSubtractRule: "SaveEstihkakSubtractRule",
        deleteEstihkakSubtractRule: "DeleteEstihkakSubtractRule",
    },
    employeeReportController: {
        reportEmployeeEsthkakSubtracts: "EmployeeReport/ReportEmployeeEsthkakSubtracts",
        reportEmployeeCashierSales: "EmployeeReport/ReportEmployeeCashierSales",
    },
    installmentPlanController: {
        getInstallmentsPlanDetails: "InstallmentPlan/DistributeInstallmentPlan",
        saveInstallmentPlan: "InstallmentPlan/SaveInstallmentPlan",
        getInstallmentsPlansByCustomerId: "InstallmentPlane/GetAllInstallmentPlanByCustomerId"
    },
    itemController: {
        searchItems: "Items/SearchItems",
        getItemListToAddInTransaction: "GetItemListToAddInTransaction",
        getItemFullDetailsById: "GetItemFullDetailsById",
        getItemImageByItemId: "Items/GetItemImageByItemId?ItemId",
        getItemConfigurations: "Items/GetItemConfigurations",
        getItemsSimplifiedModel: "Transaction/GetItemsSimplifiedModel",
        searchItemBalanceInventoryCheck: "InventoryCheck/SearchItemBalanceInventoryCheck",
        UpdateTaxPercentageOfCategory: "UpdateTaxPercentageOfCategory",
        saveItemFull: "SaveItemFull",
        deleteItem: "DeleteItem",
        saveInventoryChecking: "SaveInventoryChecking",
        getItemsForMoveBetweenStores:"MoveItemsTransaction/GetItemsForMoveBetweenStores",
    },
    itemReportController: {
        reportItemsData: "ItemsReport/ReportItemsData",
        reportBestLeastSellingItems: "ItemsReport/ReportBestLeastSellingItems",
        reportExpiryDateOfItems: "ItemsReport/ReportExpiryDateOfItems",
        reportEditUserItemData: "ItemsReport/ReportEditUserItemData",
        reportProductionItemsData: "ItemsReport/ReportProductionItemsData",
        reportItemsReachedToLimitOrder: "ItemsReport/ReportItemsReatchToLimitOrder",
        reportInventoryChecking: "ItemsReport/ReportInventoryChecking",
        reportStagnantItems: "ItemsReport/ReportStagnantItems",
        reportFollowItemBalance: "ItemsReport/ReportFolowItemBalance",
        reportSummeryItemTransaction: "ItemsReport/ReportSummeryItemTransacion",
        reportSummeryItemTransactionQuantityWithValue: "ItemsReport/ReportSummeryItemTransactionQuntityWithValue",
        reportSummeryItemTransactionQuantity: "ItemsReport/ReportSummeryItemTransactionQuntity",
        reportItemsDifferentInCost: "ItemsReport/ReportItemsDiffrentinCost"
    },
    lookupController: {
        getSortReportBestLeastSellingItems: "GetSortReportBestLeastSellingItems",
        getAllTransactionTypes: "GetAllTRasnactionTypes",
        getAllCountryIsoCode: "GetAllCountryIsoCode",
        getLookupsByTypeId: "GetLookupsByTypeId",
        getLookupByTypeId: "GetLookupByTypeId",
        getAllCalcType: "GetAllCalacType",
        getAllCostCenters: "GetAllCostCenters",
        saveLookup: "SaveLookup",
        deleteLookup: "DeleteLookup",
    },
    paymentTypeController: {
        getPaymentConfiguration: "PaymentType/GetPaymentConfiguration",
        getAllPaymentType: "PaymentType/GetAllPaymentType",
        getPaymentTypeById: "PaymentType/GetPaymentTypeById",
        savePaymentType: "PaymentType/SavePaymentType",
        deletePaymentType: "PaymentType/DeletePaymentType",
    },
    restaurantTableController: {
        getAllRestaurantTables: "GetAllResturantTables",
        getAllMainParentRestaurantTable: "ResturantTable/GetAllMainParentResturantTable",
        getAllRestuarntTableByParentId: "ResturantTable/GetAllRestuarntTableByParentId",
        saveRestaurantTable: "ResturantTable/SaveResturantTable",
        deleteRestaurantTable: "ResturantTable/DeleteResturantTable",
        getReservedTransactionByTableId: "GetReservedTransactionByTableId"
    },
    storeController: {
        getStoreList: "GetStoreList",
        saveStore: "SaveStore",
        deleteStore: "DeleteStore",
    },
    supplierReportController: {
        reportSuppliersData: "SupplierReport/ReportSuppliersData",
        reportSuppliersPayments: "SupplierReport/ReportSuppliersPayments",
        reportSuppliersAccount: "CustomerReport/ReportSuppliersAccount",
        reportAllSuppliersTransaction: "CustomerReport/ReportAllSuppliersTransaction",
    },
    transactionController: {
        validateEditTransactionUrl: "ValidateEditTransaction",
        validateDeleteTransactionUrl: "ValidateDeleteTransaction",
        validateReturnTransactionUrl: "ValidateReturnTransaction",
        searchTransactionUrl: "SearchTransaction",
        getTransactionForReturnByIdUrl: "Transaction/GetTransactionForReturnById",
        getFullTransactionByIdUrl: "Transactions/GetFullTrnsactionByID",
        getTransactionFullInfoByIdUrl: "GetTrnsactionFullInfoById",
        printTransactionReportPdf: "PrintTrasnactionReportPdf",
        deleteTransactionUrl: "DeleteTransaction",
        saveTransactionUrl: "Transactions/SaveTransactions",
        saveStoreTransferTransactionUrl: "MoveItemsTransaction/SaveTransactionMoveItemBetweenStore",
        searchStoreTransferTransactionUrl: "MoveItemsTransaction/SearchMoveItemBetweenStoresTransaction",
        printMoveItemsTransaction:"MoveItemsTransaction/PrintMoveItemsTransaction",
        cancelReservation: "CncelReservation",
        searchReservedTransactions: "/ReservedTrasnaction/SearchReservedTrasnaction",
        getReservedTransactionByID: "GetReservedTransactionByTransactionId"
    },
    transactionReportController: {
        reportItemsDifferentInVatCalculation: "Transactions/ReportItemsDiffrentInVatCalculation",
        reportStations: "Transactions/ReportStations",
        reportOrderStatusLog: "Transactions/ReportOrderStatusLog",
        reportSalesInGraph: "ReportGraph/ReportSalesInGraph",
        reportProfitSalesInGraph: "ReportGraph/ReportProfitSalesInGraph",
        salesTransactionsReport: "Transactions/ReportSalesTransactions",
        returnSalesTransactionsReport: "Transactions/ReportReturnSalesTransactions",
        allSalesAndReturnTransactionsReport: "Transactions/ReportAllSalesAndReturnTransactions",
        purchaseTransactionsReport: "Transactions/ReportPurchaseTransactions",
        returnPurchaseTransactionsReport: "Transactions/ReportReturnPurchaseTransactions",
        reportCashier: "Transactions/ReportCashier",
        reportEmployeeSales: "Transactions/ReportEmplyeeSales",
        reportProfitOfSales: "Transactions/ReportProfitOfSales",
        reportVat: "Transactions/ReportVat",
    },
    treasuryReportController: {
        reportInMoney: "TreasuryReport/ReportInMoney",
        reportOutMoney: "TreasuryReport/ReportOutMoney",
        reportInAndOutMoney: "TreasuryReport/ReportInAndOutMoney",
        reportAllInAndOutMoney: "TreasuryReport/ReportAllInAndOutMoney",
    },
    unitController: {
        getUnitList: "GetUnitList",
        saveUnit: "SaveUnit",
        deleteUnit: "DeleteUnit",
    },
    userController: {
        validateLogin: "ValidateLogin",
        getUsersList: "GetUsersList",
        getUserById: "GetUserById",
        getUserPermission: "GetUserPermission",
        getUserSettings: "GetUserSettings",
        loadMenuByUserId: "LoadMenuByUserId",
        getUserPosSettings: "UserSettings/GetUserPosSettings",
        getUserPOSPermission: "GetUserPOSPermission",
        getTransactionSettings: "GetTransactionSettings",
        getUserTransactionsSettings: "User/GetUserTransactionsSettings",
        getUserDashboardSummary: "User/DashBoardSummery",
        getUserAlert: "User/GetUserAlert",
        saveUserSettingsUrl: "SaveUserSettings",
        deleteUser: "DeleteUser",
        saveUser: "SaveUser",
        saveUserPermissions: "SaveUserPermissions",
        saveUserSettings: "SaveUserSettings",
        saveTransactionSettings: "SaveTransactionSettings",
        saveUserPosSettings: "UserSettings/SaveUserPosSettings",
        getUserBranchPermissions:"User/GetUserBranchPermmissions",
    },
    voucherController: {
        getVoucherByCode: "Voucher/GetVocherByCode",
    },
};