//https://codesandbox.io/s/ccyuu?file=/src/movies.js  -->basic
//https://codesandbox.io/s/xmdju?file=/src/index.js:1950-1966  -->server side pagination
//https://codesandbox.io/s/fz295?file=/src/DataTable.js:1750-1763 -->filter
//https://react-data-table-component.netlify.app/
import React, { FC, useState } from "react";
import DataTable, { Direction } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { isArabicCurrentLanguage } from "../../../utils";
import { DataTablePropsModel } from "../../../models";
import { ConditionalStyles } from "react-data-table-component/dist/src/DataTable/types";

type PaginationType = "none" | "client" | "server";

export interface SelectedRow<T> {
  allSelected: boolean;
  selectedCount: number;
  selectedRows: T[];
}

export const TableComponent: FC<DataTablePropsModel> = ({
  title,
  columns = [],
  data = [],
  totalRows,
  currentPage = 1,
  pageSize,
  paginationType = "none",
  subHeaderComponent,
  onPageSizeChange = () => {},
  onCurrentPageChange = () => {},
  onRowClicked = () => {},
  onRowDoubleClicked = () => {},
  onRowMouseEnter = () => {},
  onRowMouseLeave = () => {},
  conditionalRowStyles,
  selectableRows = false,
  onSelectedRowChange,
}) => {
  //#region state
  const { t } = useTranslation();
  const [activeRowId, setActiveRowId] = useState<any>(null); // Unified state for hover and click
  // const [hoveredRowId, setHoveredRowId] = useState<any>(null);
  // const [clickedRowId, setClickedRowId] = useState<any>(null);
  //#endregion
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  let isPagination: boolean = paginationType !== "none";
  let isPaginationServer: boolean = paginationType === "server";
  const paginationComponentOptions = {
    // selectAllRowsItem: true,
    // selectAllRowsItemText: t("selectAllRowsFromTable"),
    rowsPerPageText: t("rowsPerPageText"),
    rangeSeparatorText: t("rangeSeparatorText"),
  };
  const rowsPerPage = [5, 10, 25, 50, 100, 200, 300, 500];

  //#endregion
  //#region function
  const hoverStyle: ConditionalStyles<any>[] = [
    {
      when: (row: any) => {
        const rowId = row.id || row.ID || row.rowKey;
        return rowId === activeRowId;
      },
      style: {
        backgroundColor: "#e0f7fa", // Change this to your desired hover/click color
      },
    },
    // {
    //     when: (row: any) => row.id === hoveredRowId,
    //     style: {
    //         backgroundColor: '#f1f1f1', // Change this to your desired hover color
    //     },
    // },
    // {
    //     when: (row: any) => row.id === clickedRowId,
    //     style: {
    //         backgroundColor: '#d1d1d1', // Change this to your desired click color
    //     },
    // },
  ];
  const handleRowMouseEnter = (row: any, event: React.MouseEvent) => {
    //setHoveredRowId(row.id);
    const rowId = row.id || row.ID || row.rowKey;
    setActiveRowId(rowId);
    if (onRowMouseEnter) {
      onRowMouseEnter(row, event);
    }
  };
  const handleRowMouseLeave = (row: any, event: React.MouseEvent) => {
    //setHoveredRowId(null);
    setActiveRowId(null);
    if (onRowMouseLeave) {
      onRowMouseLeave(row, event);
    }
  };
  const handleRowClick = (row: any, event: React.MouseEvent) => {
    //setClickedRowId(row.id);
    setActiveRowId(row.id);
    if (onRowClicked) {
      onRowClicked(row, event);
    }
  };
  // const CustomPagination = (): any => {
  //   return (
  //     <>
  //       <Pager
  //         currentPage={currentPage}
  //         pageSize={pageSize}
  //         rowsCount={totalRows}
  //         onCurrentPageChange={onCurrentPageChange}
  //         onPageSizeChange={onPageSizeChange}
  //       />
  //     </>
  //   );
  // };
  //#endregion
  return (
    <>
      <DataTable
        title={title}
        columns={columns}
        data={data}
        paginationPerPage={pageSize}
        progressComponent={<>loading........</>}
        //progressPending={loading} // to be checked
        pagination={isPagination}
        paginationServer={isPaginationServer}
        paginationTotalRows={totalRows}
        paginationDefaultPage={currentPage}
        direction={isArabic ? Direction.RTL : Direction.LTR}
        onChangePage={(e) => {
          onCurrentPageChange(e);
          //setCurrentPage(e);
        }}
        //@ts-ignore
        onChangeRowsPerPage={(e) => {
          onPageSizeChange(e);
          //setPageSize(e);
        }}
        onRowClicked={handleRowClick}
        onRowDoubleClicked={onRowDoubleClicked}
        onRowMouseEnter={handleRowMouseEnter}
        onRowMouseLeave={handleRowMouseLeave}
        selectableRowsHighlight
        selectableRowsSingle
        selectableRows={selectableRows}
        paginationComponentOptions={paginationComponentOptions}
        paginationRowsPerPageOptions={rowsPerPage}
        onSelectedRowsChange={onSelectedRowChange}
        striped
        responsive
        fixedHeader
        //subHeader
        subHeaderComponent={subHeaderComponent}
        conditionalRowStyles={
          [...(conditionalRowStyles || []), ...hoverStyle] || []
        }
        //conditionalRowStyles={conditionalRowStyles || []}
        noDataComponent={<div></div>}
        //customStyles={{backgroundColor: 'yellow'}}
        // paginationComponent={isCustomPagination ? CustomPagination : undefined}
      />
    </>
  );
};
