import React, { FC } from "react";
import {
  getLabelName,
  isArabicCurrentLanguage,
  formatValue,
} from "../../../utils";
import { Form } from "react-bootstrap";
import {
  ControlSizeType,
  FormatValueType,
  LabelSizeType,
} from "../../../models";
type TextBoxType = "text" | "number" | "checkbox" | "textarea" | "password";

export const TextBox: FC<{
  labelName: string;
  inputName: string;
  inputValue:
    | string
    | ReadonlyArray<string>
    | number
    | boolean
    | undefined
    | null;
  errorText?: string | null;
  placeHolder?: string | undefined;
  isMandatory?: boolean | null;
  isReadOnly?: boolean | undefined;
  onChange?: any | undefined;
  onBlur?: any | undefined;
  onKeyPress?: (value: string) => void;
  type?: TextBoxType | null;
  controlSize?: ControlSizeType;
  labelSize?: LabelSizeType;
  inputRef?: React.Ref<HTMLInputElement>;
  formatType?: FormatValueType; // New prop to determine the format type
}> = ({
  labelName,
  inputName,
  inputValue,
  errorText,
  placeHolder,
  isMandatory = false,
  isReadOnly = false,
  type = "text",
  onChange,
  onBlur,
  onKeyPress, // Receive the new prop for handling Enter key press
  controlSize = "col flex-form-control",
  labelSize = "form-label",
  inputRef,
  formatType = "none",
}) => {
  const cssPrefix: string = isArabicCurrentLanguage() ? "_ar" : "_en";
  const errorClassName =
    errorText !== null && errorText !== undefined
      ? `error${cssPrefix}`
      : isMandatory &&
        (inputValue === null || inputValue === undefined || inputValue === "")
      ? `error${cssPrefix}`
      : `success${cssPrefix}`;
  const inputClassName: string =
    type === "checkbox" ? "" : `form-control ${errorClassName}`;
  const isInputField: boolean =
    type === "checkbox" ||
    type === "number" ||
    type === "text" ||
    type === "password";

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent the default behavior of Enter key (form submission)
      if (onKeyPress) {
        const value = event.currentTarget.value; // Get the current value of the input
        onKeyPress(value); // Pass the value to the onEnterKeyPress function
      }
    }
  };
  const renderError = () => {
    if (errorText) {
      return (
        <Form.Control.Feedback className="d-block" type="invalid">
          {errorText}
        </Form.Control.Feedback>
      );
    }
    return null;
  };
  return (
    <>
      <div className={controlSize} style={{ gap: 15 }}>
        {labelName && (
          <label htmlFor={inputName} className={labelSize}>
            {getLabelName(labelName)}
            {isMandatory && <span className="asterisk">*</span>}
          </label>
        )}
        {isInputField && (
          <input
            // @ts-ignore
            type={type}
            min={type === "number" ? "0" : "-1000000"}
            step={type === "number" ? "0.00001" : undefined}
            id={inputName}
            key={inputName}
            name={inputName}
            className={inputClassName}
            placeholder={placeHolder}
            readOnly={isReadOnly}
            checked={type === "checkbox" && Boolean(inputValue)}
            // @ts-ignore
            value={
              type !== "checkbox"
                ? formatValue(inputValue, formatType)
                : inputValue
            }
            // @ts-ignore
            onChange={onChange}
            // @ts-ignore
            onBlur={onBlur}
            onKeyDown={handleKeyDown} // Attach the event handler
            onWheel={(event) => {
              event.currentTarget.blur();
            }}
            ref={inputRef}
          />
        )}

        {!isInputField && (
          <textarea
            id={inputName}
            key={inputName}
            name={inputName}
            className={inputClassName}
            rows={3}
            placeholder={placeHolder}
            readOnly={isReadOnly}
            // @ts-ignore
            value={formatValue(inputValue || "")}
            // @ts-ignore
            onChange={onChange}
            // @ts-ignore
            onBlur={onBlur}
            style={{ width: "100%" }}
          />
        )}
        {renderError()}
      </div>
      {/* </div> */}
      {/* </div> */}
    </>
  );
};
