import AlYusrAxiosApiInstance from "../axios/alYusrAxiosApiInstance";
import cache from "memory-cache";
import * as CryptoJS from "crypto-js";

import {
    AccountModel,
    AcTransactionAccountResponseModel,
    AcTransactionTypeEnum,
    calcTypeApiResponseModel,
    CategoryResponseModel,
    CoastCenterModel,
    CountryResponseModel,
    CustomerResponseModel,
    DailyTransactionTypeEnum,
    DeleteLookupModel,
    DictionaryModel,
    EmployeeResponseModel,
    ItemLookupApiResponseModel,
    ItemStoreTransferLookupApiResponseModel,
    LookupApiResponseModel,
    LookupFormTypeEnum,
    LookupItemModel,
    LookupKeyValueModel,
    LookupModel,
    LookupTypeEnum,
    ResponseBaseModel,
    RestaurantResponseModel,
    TransactionTypeResponseModel,
    UnitModel, UserBranchPermissionsModel,
} from "../models";
import {
    fetchIndexedDbData,
    getLabelName,
    getUserId,
    getValueFromLookupKeyValue,
    SecureLocalStorageGet,
    SecureLocalStorageSet,
    setIndexDb,
} from "../utils";
import {AlYuserApiUrls} from "../configuration";
//#region properties
const defaultCacheMs: number = 6000 * 60 * 24; //(6000 ms *60 minutes *24 hrs *365 days)
//#endregion
//#region get
export const getReportTypes = async (): Promise<LookupApiResponseModel[]> => {
    try {
        let response: LookupApiResponseModel[] = await AlYusrAxiosApiInstance.get(
            AlYuserApiUrls.lookupController.getSortReportBestLeastSellingItems
        );
        // @ts-ignore
        return response ? response.Result : [];
    } catch (err) {
        alert(err);
    }
    // @ts-ignore
    return [];
};
export const getTransactionsTypesDictionaries = async (): Promise<
    DictionaryModel[] | null
> => {
    try {
        let result: DictionaryModel[] = [];
        const data: ResponseBaseModel<TransactionTypeResponseModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.lookupController.getAllTransactionTypes);
        if (
            data !== null &&
            data !== undefined &&
            data.Result !== null &&
            data.Result !== undefined
        ) {
            const keys = Object.keys(DailyTransactionTypeEnum).filter((v) =>
                isNaN(Number(v))
            );
            keys.forEach((row) => {
                const id: number = Number(
                    Object.values(DailyTransactionTypeEnum)[
                        Object.keys(DailyTransactionTypeEnum).indexOf(row)
                        ]
                );
                const record = data.Result?.filter((p) => p.ID == id)[0];
                result.push({
                    Id: id,
                    Name: row,
                    NameAr: record?.ArabicName || "*****",
                    NameEn: record?.EnglishName || "******",
                    TypeId: 999,
                });
            });
            return result;
        }

        return [];
    } catch (err) {
        alert(err);
    }
    return null;
};
export const getAllCountryIsoCode = async (): Promise<LookupItemModel[]> => {
    try {
        const result: ResponseBaseModel<CountryResponseModel[]> =
            await AlYusrAxiosApiInstance.get(AlYuserApiUrls.lookupController.getAllCountryIsoCode);
        // @ts-ignore
        let response: LookupItemModel[] = [];
        result.Result?.forEach((item) => {
            response.push({
                name: item.Desc_en,
                nameAr: item.Desc_ar,
                value: item.code,
            });
        });
        return response;
    } catch (err) {
        alert(err);
    }
    return [];
};
export const getLookupByType = async (
    type: LookupTypeEnum,
    isCached: boolean = false,
    addEmptySelect: boolean = false,
    searchValue: LookupKeyValueModel[] = []
): Promise<LookupItemModel[]> => {
    let url: string = "";
    let result: LookupItemModel[] = [];
    try {
        switch (type) {
            case LookupTypeEnum.AllAccounts:
                result = await getAllAccounts();
                break;
            case LookupTypeEnum.CustomerAccounts:
            case LookupTypeEnum.SupplierAccounts:
            case LookupTypeEnum.AllTreasuryInAccount:
            case LookupTypeEnum.AllTreasuryOutAccount:
            case LookupTypeEnum.CustomerRefundAccounts:
            case LookupTypeEnum.SupplierRefundAccounts:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getAcTransactionsAccountsLookupListByTransactionType(type);
                break;
            case LookupTypeEnum.CostCenters:
                result = await getAllCostCenters();
                break;
            case LookupTypeEnum.AllCategories:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getCategoriesLookupList();
                break;
            case LookupTypeEnum.AllMainParentRestaurant:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getAllRestaurantLookupList();
                break;
            case LookupTypeEnum.Customers:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getCustomers();
                break;
            case LookupTypeEnum.Employee:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getEmployees();
                break;
            case LookupTypeEnum.Supplier:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getSuppliers();
                break;
            case LookupTypeEnum.Items:
                result = isCached
                    ? //? await getIndexDbCachedData(type,SystemConfiguration.indexDb.indexDbName)
                    await getLocalStorageCachedData(type)
                    : await getItems(searchValue);
                break;
            case LookupTypeEnum.ItemsStoreTransfer:
                result = isCached
                    ? //? await getIndexDbCachedData(type,SystemConfiguration.indexDb.indexDbName)
                    await getLocalStorageCachedData(type)
                    : await getStoreTransferItems (searchValue);
                break;
            case LookupTypeEnum.Units:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getUnitsLookupList();
                break;
            case LookupTypeEnum.CalcType:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getCalcTypes();
                break;
            case LookupTypeEnum.AccountType:
                result = await getAccountTypes(isCached);
                break;
            case LookupTypeEnum.DaysOfWeek:
                result = await getDaysOfWeek(isCached);
                break;
            case LookupTypeEnum.UserBranchPermissions:
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getUserBranchPermissions();
                break;
            default:
                url = `${AlYuserApiUrls.lookupController.getLookupsByTypeId}?lookupTypeId=${Number(
                    type
                )}&userId=${getUserId()}&lang=1`;
                result = isCached
                    ? await getLocalStorageCachedData(type)
                    : await getLookupData(url);
                break;
        }
    } catch (err) {
        alert(err);
    }
    if (addEmptySelect) {
        //Add Empty Select
        result = [
            {
                nameAr: getLabelName("Select"),
                name: "Select",
                value: null,
                otherValue: {
                    categoryId: -99,
                },
            },
            ...result,
        ];
    }
    return result;
};
export const resetLookup = (type: LookupTypeEnum) => {
    const url: string = `${AlYuserApiUrls.lookupController.getLookupsByTypeId}?lookupTypeId=${Number(
        type
    )}&userId=${getUserId()}&lang=1`;
    cache.del(url);
};
export const getLookupFromByType = async (
    type: LookupFormTypeEnum
): Promise<ResponseBaseModel<LookupModel[]>> => {
    const url = `${AlYuserApiUrls.lookupController.getLookupByTypeId}?lookupTypeId=${Number(type)}`;
    return await AlYusrAxiosApiInstance.get(
        url
    );
};
//#endregion
//#region post
export const saveLookup = async (
    request: LookupModel
): Promise<ResponseBaseModel<LookupModel> | null> => {
    try {
        // @ts-ignore
        return await AlYusrAxiosApiInstance.post(
            AlYuserApiUrls.lookupController.saveLookup,
            request
        );
    } catch (err) {
        alert(err);
    }
    return null;
};
export const deleteLookup = async (
    id: number
): Promise<ResponseBaseModel<DeleteLookupModel>> => {
    let apiResponse: ResponseBaseModel<DeleteLookupModel> = {};
    try {
        apiResponse = await AlYusrAxiosApiInstance.post(`${AlYuserApiUrls.lookupController.deleteLookup}?id=${id}`);
        // @ts-ignore\
        return apiResponse;
    } catch (err) {
        alert(err);
    }
    return apiResponse;
};
//#endregion
//#region private
//#region functions
const fillIndexDbItemCache1 = (items: LookupItemModel[]) => {
    let response: LookupItemModel[] = [];
    for (let i = 1; i <= 200; i++) {
        items.forEach((row) => {
            response.push(row);
        });
    }

    const encryptedData = CryptoJS.AES.encrypt(
        JSON.stringify(response),
        "123"
    ).toString();
    const dbName = "alyuserDatabase";
    const storeName = "itemData";
    const request: IDBOpenDBRequest = window.indexedDB.open(dbName, 1);
    request.onupgradeneeded = (event) => {
        const db = request.result;
        db.createObjectStore(storeName, {keyPath: "value"});
    };

    request.onerror = function (event: any) {
        console.error("Error opening IndexedDB:", event.target.error);
    };

    request.onsuccess = function (event: any) {
        const db: IDBDatabase = event.target.result;
        const transaction: IDBTransaction = db.transaction(
            [storeName],
            "readwrite"
        );
        const store: IDBObjectStore = transaction.objectStore(storeName);
        const addRequest: IDBRequest = store.add({value: "myKey", encryptedData});
        addRequest.onsuccess = () => {
        };

        addRequest.onerror = function (event: any) {
            console.error("Error storing data in IndexedDB:", event.target.error);
        };
    };

    request.onupgradeneeded = function (event: any) {
        const db: IDBDatabase = event.target.result;
        const objectStore: IDBObjectStore = db.createObjectStore("itemData", {
            keyPath: "value",
        });
        objectStore.createIndex("name", "name", {unique: false});
    };
};
const getLocalStorageCachedData = async (
    type: LookupTypeEnum
): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[];
    const cacheKey: string =
        Object.keys(LookupTypeEnum)[Object.values(LookupTypeEnum).indexOf(type)];
    const data = SecureLocalStorageGet(cacheKey);
    if (data != null) {
        response = JSON.parse(data as string) as LookupItemModel[];
    } else {
        response = await getLookupByType(type, false, false);
        if (response != null && response.length != 0) {
            SecureLocalStorageSet(cacheKey, JSON.stringify(response));
        }
    }
    return response;
};
const getIndexDbCachedData = async (
    type: LookupTypeEnum,
    indexDbName: string
): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[];
    const cacheKey: string =
        Object.keys(LookupTypeEnum)[Object.values(LookupTypeEnum).indexOf(type)];

    const data: LookupItemModel[] = await fetchIndexedDbData<LookupItemModel[]>(
        indexDbName,
        cacheKey
    );
    if (data !== null && data.length !== 0) {
        response = data;
    } else {
        response = await getLookupByType(type, false, false);
        if (response != null && response.length != 0) {
            await setIndexDb(response, indexDbName, cacheKey);
        }
    }
    return response;
};
const getCachedData = async (
    key: string,
    type: LookupTypeEnum,
    addEmptySelect: boolean = false,
    defaultCacheMilliseconds: number = defaultCacheMs
): Promise<LookupItemModel[]> => {
    const cachedResponse = cache.get(key);
    if (cachedResponse) {
        return cachedResponse;
    } else {
        const data = await getLookupByType(type, false, addEmptySelect);
        cache.put(key, data, defaultCacheMilliseconds);
        return data;
    }
};
const getLookupData = async (url: string): Promise<LookupItemModel[]> => {
    const defaultResult: ResponseBaseModel<LookupApiResponseModel[]> =
        await AlYusrAxiosApiInstance.get(url);
    if (defaultResult && defaultResult.Result) {
        return mapLookUp(defaultResult.Result);
    }
    return [];
};
const mapLookUp = (request: LookupApiResponseModel[]): LookupItemModel[] => {
    let response: LookupItemModel[] = [];
    if (request !== null && request !== undefined && request.length !== 0) {
        request.forEach((row) => {
            response.push({
                name: row.EnglishName,
                nameAr: row.ArabicName,
                value: row.ID.toString(),
                otherValue: {
                    ValueEntity: row.ValueEntity,
                },
            });
        });
    }
    return response;
};
//#endregion
//#region api call
const getCalcTypes = async (): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[] = [];
    const fetchResult: ResponseBaseModel<calcTypeApiResponseModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.lookupController.getAllCalcType);
    if (
        fetchResult !== null &&
        fetchResult !== undefined &&
        fetchResult.Result !== null &&
        fetchResult.Result !== undefined &&
        fetchResult.Result.length !== 0
    ) {
        fetchResult.Result.forEach((row) => {
            response.push({
                value: row.ID.toString(),
                name: row.EnglishName,
                nameAr: row.Name,
                otherValue: {
                    priceType: row.PriceType,
                    percentage: row.Percentage,
                    fixedValue: row.DefaultFixedValue,
                },
            });
        });
    }
    return response;
};
const getItems = async (
    searchValue: LookupKeyValueModel[]
): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[] = [];
    let url: string = `${AlYuserApiUrls.itemController.getItemsSimplifiedModel}?searchItem.pageNumber=0&searchItem.pageSize=1000`;
    if (
        searchValue !== null &&
        searchValue !== undefined &&
        searchValue.length !== 0
    ) {
        searchValue.forEach((row) => {
            url = `${url}&${row.key}=${row.value}`;
        });
    }
    const fetchResult: ResponseBaseModel<ItemLookupApiResponseModel[]> =
        await AlYusrAxiosApiInstance.get(url);
    if (
        fetchResult !== null &&
        fetchResult !== undefined &&
        fetchResult.Result !== null &&
        fetchResult.Result !== undefined &&
        fetchResult.Result.length !== 0
    ) {
        // for (let i = 1; i <= 100; i++) {
        fetchResult.Result.forEach((row) => {
            response.push({
                value: row.ItemUnit_ID.toString(),
                name: row.ItemName_En.toLowerCase(),
                nameAr: row.ItemName.toLowerCase(),
                otherValue: {
                    categoryId: row.Categ_ID,
                    sellPrice: row.PriceSale,
                    additionalPrice: row.AddationalPrice,
                    wholeSalePrice: row.WholeSalePrice,
                    costPrice: row.PriceCost,
                    purchasePrice: row.LastPriceBuy,
                    balance: row.UnitBalance,
                    factor: row.Factor,
                    itemUnitId: row.ItemUnit_ID,
                    //unitId: row.Unit_ID,
                    code: row.Code,
                    tax: row.Tax,
                    taxPercentage: row.TaxPercentge,
                    itemInStoreId: row.ItemInstore_ID,
                    barCode: row.Barcode,
                },
            });
        });
        //}
    }
    return response;
};
const getStoreTransferItems = async (
    searchValue: LookupKeyValueModel[]
): Promise<LookupItemModel[]> => {
    const fromStoreId=getValueFromLookupKeyValue(searchValue,"fromStoreId")??-1;
    const toStoreId=getValueFromLookupKeyValue(searchValue,"toStoreId")??-1;
    const categoryId=getValueFromLookupKeyValue(searchValue,"categoryId");
    let response: LookupItemModel[] = [];
    let url: string = `${AlYuserApiUrls.itemController.getItemsForMoveBetweenStores}?itemSearchMoveItem.storeIdFrom=${fromStoreId}&itemSearchMoveItem.storeIdTo=${toStoreId}`;
    url = categoryId ? `${url}&itemSearchMoveItem.categorId=${categoryId}` : url;

    const fetchResult: ResponseBaseModel<ItemStoreTransferLookupApiResponseModel[]> =
        await AlYusrAxiosApiInstance.get(url);
    if (
        fetchResult !== null &&
        fetchResult !== undefined &&
        fetchResult.Result !== null &&
        fetchResult.Result !== undefined &&
        fetchResult.Result.length !== 0
    ) {
        // for (let i = 1; i <= 100; i++) {
        fetchResult.Result.forEach((row) => {
            response.push({
                value: row.ItemUnit_ID.toString(),
                name: row.ItemName_En.toLowerCase(),
                nameAr: row.ItemName.toLowerCase(),
                otherValue: {
                    categoryId: row.Category_Id,
                    sellPrice: row.PriceSale,
                    additionalPrice: row.AddationalPrice,
                    wholeSalePrice: row.WholeSalePrice,
                    costPrice: row.PriceCost,
                    purchasePrice: row.PriceLastBuy,
                    balance: row.Balance,
                    factor: row.Factor,
                    itemUnitId: row.ItemUnit_ID,
                    //unitId: row.Unit_ID,
                    code: row.ItemCode,
                    tax: row.Tax,
                    taxPercentage: row.TaxPercentge,
                    itemInStoreId: row.ItemInstore_ID,
                    barCode: row.Barcode,
                },
            });
        });
        //}
    }
    return response;
};
const getDaysOfWeek = async (isCashed: boolean): Promise<LookupItemModel[]> => {
    const url = `${AlYuserApiUrls.lookupController.getLookupsByTypeId}?lookupTypeId=${Number(
        LookupTypeEnum.DaysOfWeek
    )}&userId=${getUserId()}&lang=1`;
    if (isCashed) {
        const cachedResponse = cache.get(url);
        if (cachedResponse) {
            return cachedResponse;
        } else {
            const defaultResult: ResponseBaseModel<LookupApiResponseModel[]> =
                await AlYusrAxiosApiInstance.get(url);
            let data: LookupItemModel[] = [];
            if (defaultResult && defaultResult.Result) {
                if (defaultResult.Result.length !== 0) {
                    defaultResult.Result.forEach((row) => {
                        data.push({
                            name: row.EnglishName,
                            nameAr: row.ArabicName,
                            value: row.EnglishName.toString(),
                        });
                    });
                }
            }
            cache.put(url, data, 24 * 1000 * 60 * 60);
            return data;
        }
    } else {
        const defaultResult: ResponseBaseModel<LookupApiResponseModel[]> =
            await AlYusrAxiosApiInstance.get(url);
        let data: LookupItemModel[] = [];
        if (defaultResult && defaultResult.Result) {
            if (defaultResult.Result.length !== 0) {
                defaultResult.Result.forEach((row) => {
                    data.push({
                        name: row.EnglishName,
                        nameAr: row.ArabicName,
                        value: row.ID.toString(),
                    });
                });
            }
        }
        return data;
    }
};
const getAccountTypes = async (isCashed: boolean): Promise<LookupItemModel[]> => {
    const url = `${AlYuserApiUrls.lookupController.getLookupsByTypeId}?lookupTypeId=${Number(
        LookupTypeEnum.AccountType
    )}&userId=${getUserId()}&lang=1`;
    if (isCashed) {
        const cachedResponse = cache.get(url);
        if (cachedResponse) {
            return cachedResponse;
        } else {
            const defaultResult: ResponseBaseModel<LookupApiResponseModel[]> =
                await AlYusrAxiosApiInstance.get(url);
            let data: LookupItemModel[] = [];
            if (defaultResult && defaultResult.Result) {
                if (defaultResult.Result.length !== 0) {
                    defaultResult.Result.forEach((row) => {
                        data.push({
                            name: row.EnglishName,
                            nameAr: row.ArabicName,
                            value: row.EnglishName.toString(),
                        });
                    });
                }
            }
            cache.put(url, data, 24 * 1000 * 60 * 60);
            return data;
        }
    } else {
        const defaultResult: ResponseBaseModel<LookupApiResponseModel[]> =
            await AlYusrAxiosApiInstance.get(url);
        let data: LookupItemModel[] = [];
        if (defaultResult && defaultResult.Result) {
            if (defaultResult.Result.length !== 0) {
                defaultResult.Result.forEach((row) => {
                    data.push({
                        name: row.EnglishName,
                        nameAr: row.ArabicName,
                        value: row.ID.toString(),
                    });
                });
            }
        }
        return data;
    }
};
const getCustomers = async (): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[] = [];
    const customers: ResponseBaseModel<CustomerResponseModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerController.getAllCustomers);
    if (
        customers !== null &&
        customers !== undefined &&
        customers.Result !== null &&
        customers.Result !== undefined &&
        customers.Result.length !== 0
    ) {
        //console.time("res_1");
        customers.Result.forEach((row) => {
            response.push({
                value: row.ID.toString(),
                name: row.Name_En,
                nameAr: row.Name,
                otherValue: {
                    accountId: row.Account_ID,
                },
            });
        });
        // console.timeEnd("res_1");
    }
    return response;
};
const getEmployees = async (): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[] = [];
    const employees: ResponseBaseModel<EmployeeResponseModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.employeeController.getAllEmployees);
    if (
        employees !== null &&
        employees !== undefined &&
        employees.Result !== null &&
        employees.Result !== undefined &&
        employees.Result.length !== 0
    ) {
        employees.Result.forEach((row) => {
            response.push({
                value: row.ID.toString(),
                name: row.NameEn,
                nameAr: row.Name,
            });
        });
        // console.timeEnd("res_1");
    }
    return response;
};
const getSuppliers = async (): Promise<LookupItemModel[]> => {
    let response: LookupItemModel[] = [];
    const suppliers: ResponseBaseModel<CustomerResponseModel[]> =
        await AlYusrAxiosApiInstance.get(AlYuserApiUrls.customerController.getAllSuppliers);
    if (
        suppliers !== null &&
        suppliers !== undefined &&
        suppliers.Result !== null &&
        suppliers.Result !== undefined &&
        suppliers.Result.length !== 0
    ) {
        //console.time("res_1");
        suppliers.Result.forEach((row) => {
            response.push({
                value: row.ID.toString(),
                name: row.Name_En,
                nameAr: row.Name,
                otherValue: {
                    accountId: row.Account_ID,
                },
            });
        });
        // console.timeEnd("res_1");
    }
    return response;
};
export const getAllCostCenters = async (): Promise<LookupItemModel[]> => {
    let categoryResult: ResponseBaseModel<CoastCenterModel[]>;
    let response: LookupItemModel[] = [];
    categoryResult = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.lookupController.getAllCostCenters);
    if (
        categoryResult !== null &&
        categoryResult.Result !== null &&
        categoryResult.Result !== undefined &&
        categoryResult.Result.length !== 0
    ) {
        categoryResult.Result.forEach((row) => {
            response.push({
                nameAr: row.ArabicName,
                name: row.EnglishName,
                value: row.ID.toString(),
            });
        });
    }
    return response;
};
const getUnitsLookupList = async (): Promise<LookupItemModel[]> => {
    let result: ResponseBaseModel<UnitModel[]>;
    let response: LookupItemModel[] = [];
    result = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.unitController.getUnitList);
    if (
        result !== null &&
        result.Result !== null &&
        result.Result !== undefined &&
        result.Result.length !== 0
    ) {
        result.Result.forEach((row) => {
            response.push({
                nameAr: row.Name,
                name: row.Name_En,
                value: row.ID.toString(),
            });
        });
    }
    return response;
};
const getCategoriesLookupList = async (): Promise<LookupItemModel[]> => {
    let categoryResult: ResponseBaseModel<CategoryResponseModel[]>;
    let response: LookupItemModel[] = [];
    const url: string = `${AlYuserApiUrls.categoryController.getCategoryList}?lang=1`;
    categoryResult = await AlYusrAxiosApiInstance.get(url);
    if (
        categoryResult !== null &&
        categoryResult.Result !== null &&
        categoryResult.Result !== undefined &&
        categoryResult.Result.length !== 0
    ) {
        categoryResult.Result.forEach((row) => {
            response.push({
                nameAr: row.Name,
                name: row.Name_En,
                value: row.ID.toString(),
            });
        });
    }
    return response;
};
const getAllRestaurantLookupList = async (): Promise<LookupItemModel[]> => {
    let categoryResult: ResponseBaseModel<RestaurantResponseModel[]>;
    let response: LookupItemModel[] = [];
    const url: string = `${AlYuserApiUrls.restaurantTableController.getAllMainParentRestaurantTable}?lang=1`;
    categoryResult = await AlYusrAxiosApiInstance.get(url);
    if (
        categoryResult !== null &&
        categoryResult.Result !== null &&
        categoryResult.Result !== undefined &&
        categoryResult.Result.length !== 0
    ) {
        categoryResult.Result.forEach((row) => {
            response.push({
                nameAr: row.Name,
                name: row.Name_En,
                value: row.ID.toString(),
                otherValue: {
                    Active: row.Active,
                    IsPrinted: row.IsPrinted
                }
            });
        });
    }
    return response;
};
const getAllAccounts = async (): Promise<LookupItemModel[]> => {
    let categoryResult: ResponseBaseModel<AccountModel[]>;
    let response: LookupItemModel[] = [];
    response.push({
        nameAr: getLabelName("Select"),
        name: "Select",
        value: null,
    });
    categoryResult = await AlYusrAxiosApiInstance.get(AlYuserApiUrls.accountController.getAllAccounts);
    if (
        categoryResult !== null &&
        categoryResult.Result !== null &&
        categoryResult.Result !== undefined &&
        categoryResult.Result.length !== 0
    ) {
        categoryResult.Result.forEach((row) => {
            response.push({
                nameAr: row.Name,
                name: row.EnglishName,
                value: row.ID.toString(),
            });
        });
    }
    return response;
};
const getAcTransactionsAccountsLookupListByTransactionType = async (
    type: LookupTypeEnum
): Promise<LookupItemModel[]> => {
    let accountResult: ResponseBaseModel<AcTransactionAccountResponseModel[]>;
    let response: LookupItemModel[] = [];
    let url: string = `${AlYuserApiUrls.accountController.getAccountsToBindByAcTransactionType}?`;
    switch (type) {
        case LookupTypeEnum.AllTreasuryOutAccount:
            url = `${url}aCTransactionsType=${Number(
                AcTransactionTypeEnum.MoneyOut
            )}`;
            break;
        case LookupTypeEnum.AllTreasuryInAccount:
            url = `${url}aCTransactionsType=${Number(AcTransactionTypeEnum.MoneyIn)}`;
            break;
        case LookupTypeEnum.SupplierAccounts:
            url = `${url}aCTransactionsType=${Number(
                AcTransactionTypeEnum.SupplierPayments
            )}`;
            break;
        case LookupTypeEnum.CustomerAccounts:
            url = `${url}aCTransactionsType=${Number(
                AcTransactionTypeEnum.CustomerPayment
            )}`;
            break;
        case LookupTypeEnum.CustomerRefundAccounts:
            url = `${url}aCTransactionsType=${Number(
                AcTransactionTypeEnum.RefundCustomerPayments
            )}`;
            break;
        case LookupTypeEnum.SupplierRefundAccounts:
            url = `${url}aCTransactionsType=${Number(
                AcTransactionTypeEnum.RefundSupplierPayments
            )}`;
            break;
    }
    accountResult = await AlYusrAxiosApiInstance.get(url);
    if (
        accountResult !== null &&
        accountResult.Result !== null &&
        accountResult.Result !== undefined &&
        accountResult.Result.length !== 0
    ) {
        accountResult.Result.forEach((row) => {
            response.push({
                nameAr: row.ArabicName,
                name: row.EnglishName,
                value: row.ID.toString(),
            });
        });
    }
    return response;
};
const getUserBranchPermissions = async () => {
    let response: LookupItemModel[] = [];
    const fetchResult: ResponseBaseModel<UserBranchPermissionsModel[]> =
        await AlYusrAxiosApiInstance.get(`${AlYuserApiUrls.userController.getUserBranchPermissions}?userId=${getUserId()}`);
    if (
        fetchResult !== null &&
        fetchResult !== undefined &&
        fetchResult.Result !== null &&
        fetchResult.Result !== undefined &&
        fetchResult.Result.length !== 0
    ) {
        fetchResult.Result.forEach((row) => {
            response.push({
                value: row.BranchId.toString(),
                name: row.EnglishName,
                nameAr: row.ArabicName,
            });
        });
    }
    return response;
};
//#endregion
//#endregion
