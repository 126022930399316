import React from "react";
import Dropzone, {Accept, FileRejection} from "react-dropzone";
import {getLabelName} from "../../../utils";

export interface EntityUploaderProps {
    acceptTypes: Accept;
    defaultImage?: string; // Optional default image URL
    handleFileReject: (fileRejections: FileRejection[]) => void;
    handleFileDrop: (files: File[]) => void;
}

export const EntityUploader: React.FC<EntityUploaderProps> = ({
                                                                  acceptTypes,
                                                                  defaultImage,
                                                                  handleFileReject,
                                                                  handleFileDrop,
                                                              }) => {
    return (
        <Dropzone
            key={`dropZone-LogoImage64`}
            accept={acceptTypes}
            maxFiles={1}
            onDrop={(files: File[]) => {
                handleFileDrop(files);
            }}
            onDropRejected={handleFileReject}
        >
            {({getRootProps, getInputProps}) => {
                return (
                    <div>
                        <div {...getRootProps()} className="file-upload-custom">
                            <input {...getInputProps()} />
                            {defaultImage ? (
                                <img src={defaultImage} alt="Default" className="default-image" />
                            ) : (
                                <p>{getLabelName("Drag and drop here")} </p>
                            )}
                            <aside>
                                {/*<h4>Files</h4>*/}
                                {/* <ul>
                              {acceptedFiles.map((file, index) => (
                                <li key={`file_${index}`}>
                                  {file.name} - {file.size} bytes
                                </li>
                              ))}
                            </ul> */}
                            </aside>
                        </div>
                    </div>
                );
            }}
        </Dropzone>
    );
};
