import React, { FC, useMemo } from "react";
import { ActionButton, GridTable } from "..";
import { format } from "date-fns";
import {
  ActionTypeEnum,
  RequestActionModel,
  ruleExpenseMoneySearchModel,
  SearchRuleExpenseMoneyRequestModel,
} from "../../models";

import { TableColumn } from "react-data-table-component";
import { getLabelName } from "../../utils";
import { Col } from "react-bootstrap";
import { selectAcTransaction } from "../../serviceBroker/accountApiServiceBroker";

export const RuleExpenseMoneyList: FC<{
  acTransactionSearch: SearchRuleExpenseMoneyRequestModel[];
  totalRows: number;
  searchParams: SearchRuleExpenseMoneyRequestModel;
  defaultPageSize: number;
  onCurrentPageChange: Function;
  onPageSizeChange: Function;
  setShowAcTransactionModel: Function;
  setAcTransaction: Function;
  setShowDeleteModel: Function;
  setShowPrintModel: Function;
  setLoading: Function;
}> = (props) => {
  //#region variables
  const {
    acTransactionSearch,
    totalRows,
    searchParams,
    defaultPageSize,
    onCurrentPageChange,
    onPageSizeChange,
    setShowAcTransactionModel,
    setAcTransaction,
    setShowDeleteModel,
  } = props;
  const columns: TableColumn<ruleExpenseMoneySearchModel>[] = useMemo(
    () => [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        sortable: true,
        width: "80px",
      },
      {
        name: getLabelName("Number"),
        selector: (row) => row.ID,
        sortable: true,
        width: "100px",
        wrap: true,
      },

      {
        name: getLabelName("ArabicName"),
        selector: (row) => row.ArabicName,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Value"),
        selector: (row) => row.Balance,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Date"),
        selector: (row) =>
          format(new Date(row.DateCreate), "MMMM d, yyyy h:mm aa"),
        sortable: true,
        allowOverflow: true,
      },

      {
        name: getLabelName("Actions"),
        // eslint-disable-next-line react/button-has-type
        cell: (row: ruleExpenseMoneySearchModel) => (
          <>
            <Col>
              <ActionButton
                commandType="Edit"
                onClick={async () => {
                  await onActionEvent({
                    id: row.ID,
                    request: row,
                    action: ActionTypeEnum.Update,
                  });
                }}
              />
            </Col>

            <Col>
              <ActionButton
                commandType="Delete"
                onClick={async () => {
                  await onActionEvent({
                    id: row.ID,
                    request: row,
                    action: ActionTypeEnum.Delete,
                  });
                }}
              />
            </Col>
          </>
        ),
      },
    ],
    []
  );
  //#endregion
  //#region functions
  const onActionEvent = async (request: RequestActionModel) => {
    switch (request.action) {
      case ActionTypeEnum.Update:
        const response = await selectAcTransaction(request.id!);
        setAcTransaction(response.Result);
        setShowAcTransactionModel(true);
        break;
      case ActionTypeEnum.Delete:
        setShowDeleteModel({ show: true, id: request.id });
        break;
    }
  };
  //#endregion
  //#region html
  return (
    <>
      <GridTable
        columns={columns}
        data={acTransactionSearch}
        totalRows={totalRows}
        currentPage={searchParams.pageNumber || 1}
        pageSize={searchParams.pageSize || defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationType="server"
      />
    </>
  );
  //#endregion
};
