import React, { Dispatch, SetStateAction } from "react";
import _ from "lodash";
import {
  ActionTypeEnum,
  GatherItemModel,
  ItemInStoreModel,
  ItemModel,
  ItemModifierModel,
  ItemUnitModel,
  LookupItemModel,
  LookupTypeEnum,
  RequestActionModel,
  ResponseBaseModel,
  RowStateEnum,
  ValidationErrorModel,
} from "../../../models";
import { generateGuid, getLabelName } from "../../../utils";
import { saveItem } from "../../../serviceBroker/itemApiServiceBroker";
import { DataNode } from "rc-tree/lib/interface";
import { getLookupByType } from "../../../serviceBroker/lookupApiServiceBroker";
import { FaSmileBeam } from "react-icons/fa";
import { randomUUID } from "crypto";
//#region functions
//#region tree
export const onTreeNodeSelected = (
  e: DataNode[],
  setSelectedCategoryState: Dispatch<SetStateAction<number>>
) => {
  setSelectedCategoryState(Number(e[0].key));
};
//#endregion
//#region item unit
export const handleAddItemUnitRow = (
  setState: Dispatch<SetStateAction<ItemUnitModel[]>>,
  stateValue: ItemUnitModel[],
  defaultUnitID: number
) => {
  setState([...stateValue, setItemUnitInitialValues(defaultUnitID)]);
};
export const handleDeleteItemUnitRow = (
  setState: Dispatch<SetStateAction<ItemUnitModel[]>>,
  _stateValue: ItemUnitModel[],
  rowKey: string,
  isNew: boolean
) => {
  if (!isNew)
    return setState((prev) =>
      prev.map((row) => {
        if (`${row.ID}` === rowKey)
          return { ...row, rowState: RowStateEnum.Delete };
        return row;
      })
    );
  setState((prev) => prev.filter((row) => row.rowKey !== rowKey));
};
export const generateItemStore = (): ItemInStoreModel[] => {
  return [
    {
      ID: 0,
      Item_ID: 0,
      Name: "",
      Store_ID: 1,
      LimitOrder: 0,
      DefaultExpiryDays: 0,
      Status: true,
      UseExpiryDate: false,
      Item_unit: [
        {
          AddationalPriceWithTax: 0,
          Balance: 0,
          EinvoiceItemCode: "",
          IsMultiUnit: false,
          AddationalPrice: 0,
          Factor: 1, 
          HasItemUnitBarcode: false,
          ID: 0,
          IsDefault: false,
          IsSmallestUnit: false,
          ItemGathers: null,
          ItemInstore_ID: 0,
          ItemType: "",
          LastPriceBuy: 0,
          MaximumPrice: 0,
          MinimumPrice: 0,
          Name: "",
          OtherCurrencyValue: 0,
          Price: 0,PriceCost: 0,
          PriceInOtherCurency: 0,
          PriceLastBuy: 0,
          PriceQutyBegBal: 0, 
          PriceQutyBegBalWithTax: 0,
          PriceSale: 0, 
          PriceSaleInOtherCurency: 0, 
          PriceSaleWithTax: 0,
          ProfitPercentage: 0,
          QutyBegBal: 0,
          rowKey: "1234",
          rowState: 1,
          ScaleBarcode: "",
          Tax: 0,
          Transporttion_Cost: 0,
          Unit_ID: 0,
          UnitBalance:0,
          UsedInTransaction: false,
          WholeSalePrice: 0,
          WholeSalePriceWithTax: 0
                }
      ],
      rowState: Number(RowStateEnum.Add),
    },
  ];
};
export const handleChangeItemUnitValues = (
  setState: Dispatch<SetStateAction<ItemUnitModel[]>>,
  _stateValue: ItemUnitModel[],
  rowKey: string,
  key: string,
  value: any
) => {
  setState((prev) => {
    return prev.map((row) => {
      if ((row.rowKey || `${row.ID}`) === rowKey) {
        return { ...row, [key]: value };
      } else {
        return row;
      }
    });
  });
};

export const handleChangeItemUnitRow = (
  setState: Dispatch<SetStateAction<ItemUnitModel[]>>,
  stateValue: ItemUnitModel[],
  request: ItemUnitModel
) => {
  stateValue = _.map(stateValue, (row) => {
    if (row.rowKey === request.rowKey) {
      return { ...request };
    } else {
      return row;
    }
  });
  setState(stateValue);
};
//#endregion
//#region modifiers unit
export const handleAddModifierUnitRow = (
  setState: Dispatch<SetStateAction<ItemModifierModel[]>>,
  stateValue: ItemModifierModel[]
) => {
  setState([...stateValue, setItemModifierInitialValues()]);
};
export const handleDeleteItemModifierRow = (
  setState: Dispatch<SetStateAction<ItemModifierModel[]>>,
  _stateValue: ItemModifierModel[],
  rowKey: string | null,
  isNew: boolean
) => {
  if (!isNew)
    return setState((prev) =>
      prev.map((row) => {
        if (`${row.ID}` === rowKey)
          return { ...row, rowState: RowStateEnum.Delete };
        return row;
      })
    );
  setState((prev) => prev.filter((row) => row.rowKey !== rowKey));
};
export const generateItemModifier = (): ItemModifierModel[] => {
  return [
    {
      NameArabic: "",
      NameEnglish: "",
      Item_ID: 0,
      Price: 0,
      ID: 0,
      CreatedBy: 0,
      ModifiedBy: 0,
      rowState: Number(RowStateEnum.Add),
      rowKey: "",
    },
  ];
};
export const handleChangeItemModifierValues = (
  setState: Dispatch<SetStateAction<ItemModifierModel[]>>,
  stateValue: ItemModifierModel[],
  rowKey: string | null,
  key: string,
  value: any
) => {
  let obj: ItemModifierModel[] =
    stateValue !== null && stateValue !== undefined && stateValue.length !== 0
      ? stateValue
      : [];
  if (obj !== null && obj !== undefined && obj.length !== 0) {
    //@ts-ignore
    obj = _.map(obj, (row) => {
      if ((row.rowKey || `${row.ID}`) === rowKey) {
        return { ...row, [key]: value };
      } else {
        return row;
      }
    });
    setState(obj);
  }
};
//#endregion

//#region validation
export const handleValidation = (
  values: ItemModel,
  selectedCategory: number | null
): ValidationErrorModel[] => {
  let errorMessages: ValidationErrorModel[] = [];
  const itemUnits = values.ItemsInstores[0].Item_unit;

  if (values.Name === null || values.Name === undefined || values.Name === "") {
    errorMessages.push({
      MessageAr: getLabelName("nameAr.missing"),
      MessageEn: getLabelName("nameAr.missing"),
    });
  }

  // if (
  //   values.Name_En === null ||
  //   values.Name_En === undefined ||
  //   values.Name_En === ""
  // ) {
  //   errorMessages.push({
  //     MessageAr: t("nameEn.missing"),
  //     MessageEn: t("nameEn.missing"),
  //   });
  // }

  if (!selectedCategory ) {
    errorMessages.push({
      MessageAr: getLabelName("categoryId.missing"),
      MessageEn: getLabelName("categoryId.missing"),
    });
  }
  if (itemUnits === null || itemUnits === undefined || itemUnits.length === 0) {
    errorMessages.push({
      MessageAr: getLabelName("no units added to item"),
      MessageEn: getLabelName("no units added to item"),
    });
  } else if (
    itemUnits[0].Unit_ID === null ||
    itemUnits[0].Unit_ID === undefined ||
    itemUnits[0].Unit_ID === 0
  ) {
    errorMessages.push({
      MessageAr: getLabelName("no units added to item"),
      MessageEn: getLabelName("no units added to item"),
    });
  }
  if (
    errorMessages === null ||
    errorMessages === undefined ||
    errorMessages.length === 0
  ) {
    const activeUnitsCount = itemUnits.filter(
      (p: ItemUnitModel) => p.rowState !== Number(RowStateEnum.Delete)
    ).length;

    const smallestActiveUnitCount = -itemUnits.filter(
      (p: ItemUnitModel) => p.rowState !== 3 && p.IsSmallestUnit
    ).length;
    if (activeUnitsCount === 0) {
      errorMessages = errorMessages ?? [];
      errorMessages.push({
        MessageAr: getLabelName("no units added to item"),
        MessageEn: getLabelName("no units added to item"),
      });
    }
    if (activeUnitsCount > 1 && smallestActiveUnitCount === 0) {
      errorMessages = errorMessages ?? [];
      errorMessages.push({
        MessageAr: getLabelName("no smallest unit selected"),
        MessageEn: getLabelName("no smallest unit selected"),
      });
    }
    if (smallestActiveUnitCount > 1) {
      errorMessages = errorMessages ?? [];
      errorMessages.push({
        MessageAr: getLabelName("only one  smalls unit allowed"),
        MessageEn: getLabelName("only one  smalls unit allowed"),
      });
    }
  }

  return errorMessages;
};
//#endregion
//#region actions
export const handleItemReset = (
  setItemState: Dispatch<SetStateAction<ItemModel>>,
  setSelectedCategoryState: Dispatch<SetStateAction<number>>,
  setItemUnitState: Dispatch<SetStateAction<ItemUnitModel[]>>,
  setItemModifierState: Dispatch<SetStateAction<ItemModifierModel[]>>,
  setValidationErrorState: Dispatch<SetStateAction<ValidationErrorModel[]>>,
  setGatherItemState: React.Dispatch<React.SetStateAction<GatherItemModel[]>>
) => {
  setItemState(setItemInitialValues);
  setSelectedCategoryState(0);
  setItemUnitState([]);
  setItemModifierState([]);
  setValidationErrorState([]);
  setGatherItemState([]);
};
export const handleSubmit = async (
  requestObj: ItemModel,
  itemUnitList: ItemUnitModel[],
  gatherItems: GatherItemModel[],
  itemModifiersList: ItemModifierModel[],
  selectedCategory: number | null,
  onActionEvent: (o: RequestActionModel) => void,
  setItem: Dispatch<SetStateAction<ItemModel>>,
  setItemUnitList: Dispatch<SetStateAction<ItemUnitModel[]>>,
  setItemModifierList: Dispatch<SetStateAction<ItemModifierModel[]>>,
  setSelectedCategory: Dispatch<SetStateAction<number>>,
  setValidationErrors: Dispatch<SetStateAction<ValidationErrorModel[]>>,
  setGatherItemState: React.Dispatch<React.SetStateAction<GatherItemModel[]>>
): Promise<boolean> => {
  let isSubmitted: boolean = false;

  try {
    if (requestObj != null) {
      console.log("selectedCategory", selectedCategory)
      requestObj.Cat_ID = selectedCategory || 0;
      requestObj.ItemsInstores[0].Item_unit = itemUnitList;
      if (requestObj.ItemsInstores[0].Item_unit.length !== 0)
        requestObj.ItemsInstores[0].Item_unit[0].ItemGathers = gatherItems;
      requestObj.Item_Modifiers = itemModifiersList;
      if (requestObj.ItemImages !== null) requestObj.HasImage = true;
    }

    const errors = handleValidation(requestObj, selectedCategory);
    if (errors !== null && errors !== undefined && errors.length !== 0) {
      setValidationErrors(errors);
      return false;
    }
    if (
      requestObj.ItemImages &&
      requestObj.ItemImages.File64Bit !== null &&
      requestObj.ItemImages.File64Bit !== undefined
    ) {
      requestObj.ItemImages.File64Bit = requestObj.ItemImages.File64Bit.replace(
        /^data:image\/(jpeg|png|jpg);base64,/,
        ""
      );
    }
    //@ts-ignore
    const res: ResponseBaseModel<ItemModel> = await saveItem(requestObj);
    if (res != null && res.Errors != null && res.Errors.length !== 0) {
      setValidationErrors(res.Errors);
    } else {
      isSubmitted = true;
      handleItemReset(
        setItem,
        setSelectedCategory,
        setItemUnitList,
        setItemModifierList,
        setValidationErrors,
        setGatherItemState
      );
      onActionEvent({
        id: 0,
        action: ActionTypeEnum.Success,
        request: res?.Result,
      });
    }
  } catch (err: any) {
    const errors: ValidationErrorModel[] = [{ MessageAr: err, MessageEn: err }];
    setValidationErrors(errors);
  }
  return isSubmitted;
};
//#endregion
//#region others
export const handleItemComponentLoad = async (
  setLoading: Dispatch<SetStateAction<boolean>>,
  setUnitList: Dispatch<SetStateAction<LookupItemModel[]>>,
  setCategoryList: Dispatch<SetStateAction<LookupItemModel[]>>
) => {
  setLoading(true);
  const [units,categories]=await Promise.all([
    getLookupByType(LookupTypeEnum.Units),
    getLookupByType(LookupTypeEnum.AllCategories),
  ]);
  setCategoryList(categories);
  setUnitList(units);
  setLoading(false);
};
export const handleRefreshItemComponent = async (
  formik: any,
  setItem: Dispatch<SetStateAction<ItemModel>>,
  setSelectedCategory: Dispatch<SetStateAction<number>>,
  setIsRefresh: Dispatch<SetStateAction<boolean>>,
  setValidationErrors: Dispatch<SetStateAction<ValidationErrorModel[]>>,
  setGatherItems: React.Dispatch<React.SetStateAction<GatherItemModel[]>>,
  request?: ItemModel | null,
  setItemUnitList?: Dispatch<SetStateAction<ItemUnitModel[]>>,
  setItemModifierList?: React.Dispatch<
    React.SetStateAction<ItemModifierModel[]>
  >
) => {
  //@ts-ignore
  setItem(request);
  setValidationErrors([]);
  setSelectedCategory(
    request !== null &&
      request !== undefined &&
      request.Cat_ID !== null &&
      request.Cat_ID !== undefined
      ? request.Cat_ID
      : 0
  );
  setItemUnitList?.(
    request !== null && request !== undefined
      ? request.ItemsInstores[0].Item_unit
      : setItemInitialValues.ItemsInstores[0].Item_unit
  );
  setItemModifierList?.(
    request !== null && request !== undefined && request.Item_Modifiers !== null
      ? request.Item_Modifiers
      : []
  );
  setGatherItems(
    request !== null &&
      request !== undefined &&
      request.ItemsInstores[0].Item_unit[0].ItemGathers !== null &&
      request.ItemsInstores[0].Item_unit[0].ItemGathers !== undefined &&
      request.ItemsInstores[0].Item_unit[0].ItemGathers.length !== 0
      ? request.ItemsInstores[0].Item_unit[0].ItemGathers
      : []
  );
  if (request !== null && request !== undefined) {
    formik.setValues(request);
  }
  setIsRefresh(false);
};
//#endregion
//#endregion
//#region variables
export const setItemUnitInitialValues = (
  defaultUnitID: number
): ItemUnitModel => {
  const rowKey = generateGuid();
  return {
    ID: 0,
    Name: "",
    ItemInstore_ID: 0,
    Unit_ID: defaultUnitID,
    Price: 0,
    PriceSale: 0,
    PriceSaleWithTax: 0,
    PriceSaleInOtherCurency: 0,
    OtherCurrencyValue: 0,
    QutyBegBal: 0,
    Factor: 0,
    UnitBalance: 0,
    PriceQutyBegBal: 0,
    PriceQutyBegBalWithTax: 0,
    PriceInOtherCurency: 0,
    LastPriceBuy: 0,
    MinimumPrice: 0,
    MaximumPrice: 0,
    AddationalPrice: 0,
    AddationalPriceWithTax: 0,
    WholeSalePrice: 0,
    WholeSalePriceWithTax: 0,
    PriceCost: 0,
    Balance: 0,
    Tax: 0,
    PriceLastBuy: 0,
    ProfitPercentage: 0,
    Transporttion_Cost: 0,
    IsSmallestUnit: false,
    IsDefault: false,
    IsMultiUnit: false,
    HasItemUnitBarcode: false,
    UsedInTransaction: false,
    Barcode: "",
    ScaleBarcode: "",
    ItemType: "",
    EinvoiceItemCode: "",
    ItemGathers: null,
    rowState: RowStateEnum.Add,
    rowKey: rowKey,
  };
};
export const setItemModifierInitialValues = (): ItemModifierModel => {
  const rowKey = generateGuid();
  return {
    NameArabic: "",
    NameEnglish: "",
    Item_ID: 0,
    Price: 0,
    ID: 0,
    CreatedBy: 0,
    ModifiedBy: 0,
    rowState: Number(RowStateEnum.Add),
    rowKey: rowKey,
  };
};

export const setItemInitialValues: ItemModel = {
  Code: "",
  Name_En: "",
  Notes: "",
  Cat_ID: 0,
  StagnantPeriod: 0,
  UserID: 0,
  TaxValue: null,
  OrderLimit: 0,
  OpenPrice: false,
  IsActive: false,
  DisplayIndex: 0,
  TobaccoTax: false,
  TobaccoValue: 0,
  UseExpiryDate: false,
  ExpireDay: 0,
  ShowInPOS: false,
  GatherItem: false,
  OpenItem: false,
  WithoutBalance: false,
  HasModifiers: false,
  CanAddAddationalCost: false,
  HasImage: false,
  IsProduction: false,
  HasSerial: false,
  Status: false,
  IsIncludeTobaccoTax: false,
  TobaccoTaxPercentage: 0,
  IsUnisSeparateInBalance: false,
  IsItemVanishConsign: false,
  ItemsInstores: generateItemStore(),
  DeleteOldSaveItemUnitGather: false,
  IsUpdateItemPriceInOtherStores: false,
  UpdateGatherItemCost: false,
  IsDifferentFactor: false,
  Item_Supplier_ID: 0,
  Is_Filter_Service: false,
  Is_Oil_Service: false,
  DisplySequence: 0,
  ID: 0,
  CreatedBy: 0,
  ModifiedBy: 0,
  Name: "",
  Item_Modifiers: null,
  CreationDate: "",
  ModificationDate: "",
  VerifyOnUpdate: false,
  rowState: Number(RowStateEnum.Add),
  ItemImages: null,
};
//#endregion
