import React from "react";
import {
  ActionTypeEnum,
  BeneficiaryResponseModel,
  RequestActionModel,
  TransactionDetailResponseModel,
} from "../../../../../models";
import useOrdersSearch from "../hooks/useOrdersSearch";
import { TransactionOrdersForm, TransactionOrdersList } from "../../../..";
import { getreservedTransactionByID } from "../../../../../serviceBroker/transactionApiServiceBroker";

interface TransactionOrdersProps {
  transactionData: TransactionDetailResponseModel;
  onActionEvent: (o: RequestActionModel) => void;
  beneficiary: BeneficiaryResponseModel;
  closeModal: () => void;
}

const TransactionOrders: React.FC<TransactionOrdersProps> = ({
  onActionEvent,
  transactionData,
  beneficiary,
  closeModal,
}) => {
  // const initialSearchParams = {
  //   customerId: transactionData.Customer_ID,
  //   employeeId: transactionData.Emp_ID,
  //   userId: transactionData.User_Create,
  // };
  const {
    setSearchParams,
    searchParams: { pageNumber, pageSize },
    searchedTransactions,
    initialValues: initialSearchParams,
    totalCount,
    isLoading: transactionListLoading,
  } = useOrdersSearch();

  const setPageNumber = (pageNumber: number) => {
    setSearchParams((prev) => ({
      ...prev,
      pageNumber: { ...prev.pageNumber, value: pageNumber },
    }));
  };

  const setPageSize = (pageSize: number) => {
    setSearchParams((prev) => ({
      ...prev,
      pageSize: { ...prev.pageSize, value: pageSize },
    }));
  };

  const handleSelectTransaction = async (transactionID: number) => {
    const transaction = await getreservedTransactionByID(transactionID);
    console.log("transaction", transaction);
    onActionEvent({
      action: ActionTypeEnum.SelectReservedTransaction,
      request: transaction,
    });
    closeModal();
  };

  return (
    <>
      <TransactionOrdersForm
        setSearchParams={setSearchParams}
        beneficiary={beneficiary}
        initialSearchParams={initialSearchParams}
      />
      <TransactionOrdersList
        isLoading={transactionListLoading}
        searchedTransactions={searchedTransactions}
        pageSize={pageSize.value as number}
        pageNumber={pageNumber.value as number}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
        totalCount={totalCount}
        handleSelectTransaction={handleSelectTransaction}
      />
    </>
  );
};

export default TransactionOrders;
