import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AcTransactionTypeEnum,
  LookupItemModel,
  RequestActionModel,
  SearchAcTransactionRequestModel,
  AcTransactionSearchModel,
  HasFormIdModel,
} from "../../models";
import { TableColumn } from "react-data-table-component";
import {
  generateGuid,
  getLabelName,
  getLookUpItemValue,
  isArabicCurrentLanguage,
} from "../../utils";
import { searchAcTransactionTreasury } from "../../serviceBroker/acTransactionsApiServiceBroker";
import { SelectBox, InputDatePicker, LoadingBox, ButtonBox,PrivilegesChecker,GridTable } from "..";
import { getTransactionListColumns } from "./uiHelper/dataTableColumns";

interface AcTransactionListProps extends HasFormIdModel {
  acTransactionType: AcTransactionTypeEnum;
  accountLookups: LookupItemModel[];
  userLookUps: LookupItemModel[];
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  onActionEvent: (o: RequestActionModel) => void;
}

export const AcTransactionList: FC<AcTransactionListProps> = ({
  acTransactionType,
  accountLookups,
  userLookUps,
  setIsRefresh,
  isRefresh,
  onActionEvent = () => {},
  formId,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const defaultPageSize: number = 50;
  const initialValues: SearchAcTransactionRequestModel = {
    pageNumber: 1,
    pageSize: defaultPageSize,
    acTransactionType: +acTransactionType,
  };
  const columns: TableColumn<AcTransactionSearchModel>[] = useMemo(
    () => [...getTransactionListColumns(isArabic, onActionEvent, formId)],
    []
  );
  //#endregion
  //#region state
  const [searchParams, setSearchParams] =
    useState<SearchAcTransactionRequestModel>(initialValues);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AcTransactionSearchModel[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const accountsSelectBoxMultiselectRef = useRef<any>();
  const usersSelectBoxMultiselectRef = useRef<any>();
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      await getTransactions();
      setLoading(false);
    };
    fillData().then(() => {});
  }, [searchParams.pageNumber, searchParams.pageSize, searchParams.searchGuid]);
  useEffect(() => {
    if (isRefresh) {
      const fillData = async () => {
        setLoading(true);
        await getTransactions();
        setLoading(false);
      };
      fillData().then(() => {});
      setIsRefresh(false);
    }
  }, [isRefresh]);
  //#endregion
  //#region functions
  const getTransactions = async () => {
    const result = await searchAcTransactionTreasury(searchParams);
    setData(result?.Result || []);
    setTotalRows(result?.TotalRecoredCount || 0);
  };
  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchParams.pageNumber) {
      const obj = { ...searchParams };
      obj.pageNumber = pageNumber;
      setSearchParams(obj);
    }
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchParams.pageSize) {
      const obj = { ...searchParams };
      obj.pageSize = pageSize;
      setSearchParams(obj);
    }
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <div className="row row-cols-1 row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-2 row-cols-sm-1 g-md-4 g-sm-4">
        <SelectBox
          labelName={getLabelName("Account")}
          isSingleSelect={true}
          selectedValues={
            searchParams.accountId ? [searchParams.accountId.toString()] : [""]
          }
          source={accountLookups}
          onStatusChange={(e: LookupItemModel) => {
            const val = getLookUpItemValue(e);
            setSearchParams({
              ...searchParams,
              accountId: val === null ? null : Number(val),
            });
          }}
          multiselectRef={accountsSelectBoxMultiselectRef}
        />
        <SelectBox
          labelName={getLabelName("User")}
          isSingleSelect={true}
          selectedValues={
            searchParams.userId ? [searchParams.userId.toString()] : [""]
          }
          source={userLookUps}
          onStatusChange={(e: LookupItemModel) => {
            const val = getLookUpItemValue(e);
            setSearchParams({
              ...searchParams,
              userId: val === null ? null : Number(val),
            });
          }}
          multiselectRef={usersSelectBoxMultiselectRef}
        />
        <InputDatePicker
          selectedDate={searchParams.toDate}
          isClearable
          InputLabel={"To Date"}
          selectsEnd
          startDate={searchParams.fromDate}
          className="form-control"
          endDate={searchParams.toDate}
          minDate={searchParams.fromDate}
          onChange={(date) => {
            setSearchParams({
              ...searchParams,
              toDate: date === null ? undefined : date,
            });
          }}
        />
      </div>
      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-end">
        <PrivilegesChecker formId={formId} action="EnableSearch">
          <ButtonBox
            className="btn-sm"
            variant="primary"
            onClick={() => {
              setSearchParams({ ...searchParams, searchGuid: generateGuid() });
              //onActionEvent({request: null, action: ActionTypeEnum.Search})
            }}
          >
            {getLabelName("Search")}
          </ButtonBox>
        </PrivilegesChecker>
        <ButtonBox
          variant="danger"
          className="btn-sm"
          onClick={() => {
            accountsSelectBoxMultiselectRef.current.clearValue();
            usersSelectBoxMultiselectRef.current.clearValue();
            setSearchParams({
              ...initialValues,
              searchGuid: generateGuid(),
            });
          }}
        >
          {getLabelName("Cancel")}
        </ButtonBox>
      </div>
      <GridTable
        columns={columns}
        data={data}
        totalRows={totalRows}
        currentPage={searchParams.pageNumber || 1}
        pageSize={searchParams.pageSize || defaultPageSize}
        onCurrentPageChange={onCurrentPageChange}
        onPageSizeChange={onPageSizeChange}
        paginationType="server"
      />
    </>
  );
  //#endregion
};
