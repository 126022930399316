import { t } from "i18next";
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LoadingBox, PrivilegesChecker, SearchBox } from "..";
import {
  ActionTypeEnum,
  LookupItemModel,
  LookupTypeEnum, PageEnum,
  RequestActionModel,
  SearchItemApiResponseModel,
  SearchItemRequestModel,
  SearchItemsTypeEnum, TableActionColumnsStyleEnum,
} from "../../models";
import { searchItems } from "../../serviceBroker/itemApiServiceBroker";
import { getLookupByType } from "../../serviceBroker/lookupApiServiceBroker";
import { isArabicCurrentLanguage } from "../../utils";
import { handleGenerateSubHeaderComponent } from "./uiHelper/dataTableColumns";
import { useSearchBox } from "../../hooks";
import { GridTable } from "..";

export const ItemList: FC<{
  onActionEvent: (o: RequestActionModel) => void;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  isTableLoading: boolean;
  setTableLoading: Dispatch<SetStateAction<boolean>>;
}> = ({
  onActionEvent = () => {},
  setIsRefresh,
  isRefresh = false,
  isTableLoading,
  setTableLoading,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const defaultPageSize: number = 10;
  //#endregion
  //#region state
  const { onChange, onSearch, searchedWord, value } = useSearchBox();
  const [data, setData] = useState<SearchItemApiResponseModel[]>([]);
  const [categoryList, setCategoryList] = useState<LookupItemModel[]>([]);
  const [searchItemRequest, setSearchItemRequest] =
    useState<SearchItemRequestModel>({
      pageNumber: 1,
      pageSize: defaultPageSize,
      searchType: SearchItemsTypeEnum.ItemName,
      smallestUnit: true,
      searchValue: searchedWord,
    });
  const [totalRows, setTotalRows] = useState(0);
  const filterSelectBoxMultiselectRef = useRef<any>();
  const filterGroupSelectBoxMultiselectRef = useRef<any>();
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setTableLoading(true);
      await getItemsAndCategories();
      setTableLoading(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      setTableLoading(true);
      await getItems();
      setTableLoading(false);
    };
    fillData().then(() => {});
  }, [
    searchItemRequest.pageNumber,
    searchItemRequest.pageSize,
    searchItemRequest.searchGuid,
    searchItemRequest.searchValue,
  ]);
  useEffect(() => {
    if (isRefresh) {
      const fillData = async () => {
        setTableLoading(true);
        await getItems();
        setTableLoading(false);
      };
      fillData().then(() => {});
      setIsRefresh(false);
    }
  }, [isRefresh]);

  useEffect(() => {
    setSearchItemRequest((prev) => ({ ...prev, searchValue: searchedWord }));
  }, [searchedWord]);

  //#endregion
  //#region functions
  const getItemsAndCategories = async () => {
    const [categories,items]=await Promise.all([getLookupByType(LookupTypeEnum.CategoryType),searchItems(searchItemRequest)]);
    setData(items?.Result || []);
    setTotalRows(items?.TotalRecoredCount || 0);
    setCategoryList(categories);
  }
  const getCategories = async () => {
    const categoryLookupList = await getLookupByType(
      LookupTypeEnum.CategoryType
    );
    setCategoryList(categoryLookupList);
  };
  const getItems = async () => {
    const result = await searchItems(searchItemRequest);
    setData(result?.Result || []);
    setTotalRows(result?.TotalRecoredCount || 0);
  };
  const onCurrentPageChange = async (pageNumber: number) => {
    if (pageNumber !== searchItemRequest.pageNumber) {
      const obj = { ...searchItemRequest };
      obj.pageNumber = pageNumber;
      setSearchItemRequest(obj);
    }
  };
  const onPageSizeChange = async (pageSize: number) => {
    if (pageSize !== searchItemRequest.pageSize) {
      const obj = { ...searchItemRequest };
      obj.pageSize = pageSize;
      setSearchItemRequest(obj);
    }
  };

  //#endregion
  //#region component
  const generateSubHeaderComponent = useMemo(() => {
    return handleGenerateSubHeaderComponent(
      categoryList,
      searchItemRequest,
      setSearchItemRequest,
      filterSelectBoxMultiselectRef,
      filterGroupSelectBoxMultiselectRef,
      t
    );
  }, [searchItemRequest, categoryList]);
  //#endregion
  //#region html
  return (
    <>
      <LoadingBox isLoading={isTableLoading}>
        <PrivilegesChecker formId={9} action="EnableSearch">
          <SearchBox
            onChangeHandler={onChange}
            onSearchHandler={onSearch}
            searchedWord={value}
            name="name"
          />
        </PrivilegesChecker>
        <GridTable
          data={data}
          totalRows={totalRows}
          currentPage={searchItemRequest.pageNumber || 1}
          pageSize={searchItemRequest.pageSize || defaultPageSize}
          onCurrentPageChange={onCurrentPageChange}
          onPageSizeChange={onPageSizeChange}
          paginationType="server"
          subHeaderComponent={generateSubHeaderComponent}
          columnsProps={{
            actions: [
              ActionTypeEnum.Update,
              ActionTypeEnum.Delete,
              ActionTypeEnum.Print,
            ],

            onActionEvent,
            isArabic,
          }}
          formId= {PageEnum.Items}
          // style ={TableActionColumnsStyleEnum.Dropdown}
        />
      </LoadingBox>
    </>
  );
  //#endregion
};
