import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ActionTypeEnum,
  calcTypeApiResponseModel,
  CalcTypeEnum,
  GenericSearchRequestModel,
  HasFormIdModel,
} from "../../models";
import { TableColumn } from "react-data-table-component";
import {checkActionPrivilege, getLabelName, isArabicCurrentLanguage} from "../../utils";
import { getAllCalcTypes } from "../../serviceBroker/calcTypeServiceBroker";
import { ButtonBox, LoadingBox, GridTable } from "..";

interface CalcTypeListProps extends HasFormIdModel {
  calcType: CalcTypeEnum;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
  onActionEvent?: any | null;
}

export const CalcTypeList: FC<CalcTypeListProps> = ({
  calcType,
  setIsRefresh,
  isRefresh,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const isArabic: boolean = isArabicCurrentLanguage();
  //#endregion
  //#region state
  const columns: TableColumn<calcTypeApiResponseModel>[] = useMemo(
    () => [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        width: "80px",
      },
      {
        name: getLabelName("Name_Ar"),
        selector: (row) => row.Name,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("English Name"),
        selector: (row) => row.EnglishName,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Percentage"),
        selector: (row) => row.Percentage,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Price"),
        selector: (row) =>
          isArabic ? row.PriceNameAr || "" : row.PriceNameEn || "",
        sortable: true,
        allowOverflow: true,
      },
      {
          button:true,
          // eslint-disable-next-line react/button-has-type
        cell: (row: calcTypeApiResponseModel) => (
            checkActionPrivilege({action:"EnableUpdate" ,formId:formId}) && (
            <ButtonBox
              id="modifyItemBtn"
              className="btn-xs"
              onClick={() => {
                onActionEvent({
                  id: row.ID,
                  request: row,
                  action: ActionTypeEnum.Update,
                });
              }}
            >
              {getLabelName("Edit")}
            </ButtonBox>
            )
        ),
      },
        {
            button: true,
            // eslint-disable-next-line react/button-has-type
            cell: (row: calcTypeApiResponseModel) => (
                checkActionPrivilege({action: "EnableDelete", formId: formId}) && (
                    <ButtonBox
                        id="deleteItemBtn"
                        variant="danger"
                        className="btn-xs"
                        onClick={() => {
                            onActionEvent({
                                id: row.ID,
                                request: row,
                                action: ActionTypeEnum.Delete,
                            });
                        }}
                    >
                        {getLabelName("Delete")}
                    </ButtonBox>
                )
            ),
        },
    ],
    [isArabic]
  );
  const [searchParams] = useState<GenericSearchRequestModel>({
    pageSize: 10,
    pageNumber: 1,
  });
  const [data, setData] = useState<calcTypeApiResponseModel[]>([]);
  const [loading, setLoading] = useState(false);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      setLoading(true);
      setData(await getList());
      setLoading(false);
      setIsRefresh(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (isRefresh) {
        setLoading(true);
        setData(await getList());
        setLoading(false);
        setIsRefresh(false);
      }
    };
    fillData().then(() => {});
  }, [isRefresh]);
  //#endregion
  //#region functions
  const getList = async (): Promise<calcTypeApiResponseModel[]> => {
    const priceList = await getAllCalcTypes(calcType !== CalcTypeEnum.CalcType);
    return priceList !== null &&
      priceList !== undefined &&
      priceList.Result !== null &&
      priceList.Result !== undefined &&
      priceList.Result?.length !== 0
      ? priceList.Result
      : [];
  };
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      <GridTable
        columns={columns}
        data={data}
        totalRows={data.length}
        currentPage={searchParams.pageNumber}
        pageSize={searchParams.pageSize}
        // onCurrentPageChange={onCurrentPageChange}
        // onPageSizeChange={onPageSizeChange}
        paginationType="client"
      />
    </>
  );
  //#endregion
};
