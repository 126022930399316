import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { TableColumn } from "react-data-table-component";
import {
  ButtonBox,
  LoadingBox,
  PrivilegesChecker,
  SearchBox,
    GridTable,
} from "..";
import {
  ActionTypeEnum,
  CategoryResponseModel,
  GenericSearchRequestModel,
  HasFormIdModel,
  LoadingManagerProps,
  LoadingObjectsEnum,
  RequestActionModel,
} from "../../models";
import {checkActionPrivilege, getLabelName, isArabicCurrentLanguage, sortByDate} from "../../utils";
import { getCategories } from "../../serviceBroker/categoryApiServiceBroker";
import { useSearchBox } from "../../hooks";

const getDisplayedCategories = (
  categories: CategoryResponseModel[],
  searchedWord: string
) => {
  const filteredData = categories.filter(
    ({ Name, Name_En }) =>
      Name_En.includes(searchedWord) || Name.includes(searchedWord)
  );
  return sortByDate(filteredData, "desc");
};

interface CategoryListProps extends LoadingManagerProps, HasFormIdModel {
  onActionEvent: (o: RequestActionModel) => void;
  setIsRefresh: Dispatch<SetStateAction<boolean>>;
  isRefresh: boolean;
}

export const CategoryList: FC<CategoryListProps> = ({
  onActionEvent = () => {},
  setIsRefresh,
  isRefresh = false,
  hideLoader,
  loadingData,
  showLoader,
  formId,
}) => {
  //#region variables
  const isArabic = isArabicCurrentLanguage();
  const { onChange, onSearch, searchedWord, value } = useSearchBox();
  //#endregion
  //#region state
  const columns: TableColumn<CategoryResponseModel>[] = useMemo(
    () => [
      {
        name: "#",
        selector: (_row, index) => Number(index || 0) + 1,
        sortable: true,
      },
      {
        name: getLabelName("Code"),
        selector: (row) => row.Code,
        sortable: true,
      },
      {
        name: getLabelName("Name"),
        selector: (row) => row.Name,
        sortable: true,
        wrap: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Eng Name"),
        selector: (row) => row.Name_En,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Discount Default Ration"),
        selector: (row) => row.Default_Discount_Percentage,
        sortable: true,
        allowOverflow: true,
      },
      {
        name: getLabelName("Disply Sequence"),
        selector: (row) => row.DisplySequence,
        sortable: true,
        allowOverflow: true,
      },
      {
          button:true,
          // eslint-disable-next-line react/button-has-type
        cell: (row: any) => (
            checkActionPrivilege({action:"EnableUpdate" ,formId:formId}) && (
            <ButtonBox
              id="modifyItemBtn"
              className="btn-xs"
              onClick={() => {
                onActionEvent({
                  id: row.ID,
                  request: row,
                  action: ActionTypeEnum.Update,
                });
              }}
            >
              {getLabelName("Edit")}
            </ButtonBox>
        )),
      },
        {
            button: true,
            // eslint-disable-next-line react/button-has-type
            cell: (row: any) => (
                checkActionPrivilege({action: "EnableDelete", formId: formId}) && (
                    <ButtonBox
                        id="deleteItemBtn"
                        variant="danger"
                        className="btn-xs"
                        onClick={() => {
                            onActionEvent({
                                id: row.ID,
                                request: row,
                                action: ActionTypeEnum.Delete,
                            });
                        }}
                    >
                        {getLabelName("Delete")}
                    </ButtonBox>
                )
            ),
        },
    ],
    [isArabic]
  );
  const [searchParams] = useState<GenericSearchRequestModel>({
    pageSize: 10,
    pageNumber: 1,
  });
  const [data, setData] = useState<CategoryResponseModel[]>([]);
  const displayedData = getDisplayedCategories(data, searchedWord);
  //#endregion
  //#region useEffect
  useEffect(() => {
    const fillData = async () => {
      showLoader(LoadingObjectsEnum.LIST);
      setData(await getList());
      hideLoader(LoadingObjectsEnum.LIST);
      setIsRefresh(false);
    };
    fillData().then(() => {});
  }, []);
  useEffect(() => {
    const fillData = async () => {
      if (isRefresh) {
        showLoader(LoadingObjectsEnum.LIST);
        setData(await getList());
        hideLoader(LoadingObjectsEnum.LIST);
        setIsRefresh(false);
      }
    };
    fillData().then(() => {});
  }, [isRefresh]);
  //#endregion
  //#region functions
  const getList = async (): Promise<CategoryResponseModel[]> => {
    const list = await getCategories();
    return list !== null &&
      list !== undefined &&
      list.Result !== null &&
      list.Result !== undefined &&
      list.Result?.length !== 0
      ? list.Result
      : [];
  };
  //#endregion
  //#region html
  return (
    <LoadingBox isLoading={loadingData.LIST}>
      <PrivilegesChecker formId={formId} action="EnableSearch">
        <SearchBox
          name={getLabelName("category")}
          onChangeHandler={onChange}
          searchedWord={value}
          onSearchHandler={onSearch}
        />
      </PrivilegesChecker>
      <GridTable
        columns={columns}
        data={displayedData}
        totalRows={data.length}
        currentPage={searchParams.pageNumber}
        pageSize={searchParams.pageSize}
        paginationType="client"
      />
    </LoadingBox>
  );
  //#endregion
};
