import React, { FC, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import  { Accept, FileRejection } from "react-dropzone";
import {
  CompanySettingModel,
  HasFormIdModel,
  ValidationErrorModel,
} from "../../models";
import {getLabelName, setFormikFieldValueByName} from "../../utils";
import { Card, Col, Form, Row } from "react-bootstrap";
import {
  ButtonBox,
  ErrorValidationBox,
  LoadingBox,
  TextBox,
  PrivilegesChecker, EntityUploader,
} from "..";
import { saveCompanySetting } from "../../serviceBroker/companySettingApiServiceBroker";

interface CompanySettingDetailsProps extends HasFormIdModel {
  request?: CompanySettingModel | null;
  onComplete?: any | null;
}

export const CompanySettingDetails: FC<CompanySettingDetailsProps> = ({
  request,
  formId,
  onComplete = () => {},
}) => {
  //#region variables
  const allowedMimeTypes: string[] = ["image/png", "image/jpg", "image/jpeg"];
  const acceptTypes: Accept = { allowedMimeTypes };
  const initialValues: CompanySettingModel = request ?? {
    FormatDecimal: "",
    DefaultCurrency_ID: 0,
    Currency_ID: 0,
    CurrencyShortCut: "",
    IsTrialVersion: false,
    SoftwareName: undefined,
    Advert: undefined,
    PrintItemPrescriptionOfTransaction: false,
    BacckGroundImage: "",
    LogoImage: "",
    BackColor: 0,
    BackGroundImageName: "",
    LogoImageName: "",
    Phone: "",
    Mail: "",
    Password: "",
    DateType: false,
    TextLogo: "",
    ValueOfPoint: 0,
    SendMailOfSalesOnClose: false,
    RecivedMail: "",
    Header: undefined,
    DecimalPlace: 0,
    PathBackUp: "",
    UserNameSMS: "",
    PasswordSMS: "",
    MobileReceiver: "",
    SendSMS: false,
    SenderName: "",
    SetScaleOnPrice: false,
    SetScaleWeightonKG: false,
    UseOldModelForSearch: false,
    DefaultTaxPercentage: 0,
    ApplyTax: false,
    TaxNumber: "",
    HideItemFromCloseDay: false,
    MaxQuantity: 0,
    TransferTaxToDiscount: false,
    ShowItemPriceWithoutTax: false,
    PrintItemArabicEngInBill: false,
    Company_Address: "",
    CalcDiscountWithVAT: false,
    SetCostPriceZeroOnSalePriceZero: false,
    Is_Company_Authorized_Tobacco: false,
    WaterMarkImage: "",
    ServiceUI: "",
    IsUploadTransactionToCloud: false,
    Printer_Tablet_List: "",
    SystemSettings: "",
    IncludeVatOnEditPrice: false,
    DaysOfDeleteBackupFiles: 0,
    IsCodeTransactionSeparated: false,
    CompanyAddress: "",
    IsSentEmailReportAfterCloseDayOnly: false,
    CompanySetting_UniqueId: "",
    TermsAndCondations: "",
    IsFloorSecondeCurrency: false,
    CheckBalanceOfItemOnAdd: false,
    LogoImage64: "",
    ValueOfCurrency: 0,
    FormatDate: "",
    ArabicLanguage: "",
    EnglishLanguage: "",
    voucherSettings: undefined,
    AttachmentFolder: undefined,
    IsUploadShipmentTrackToCloud: false,
    Token: undefined,
    IsEnableToUploadEinvoice: false,
    ID: 0,
    CreatedBy: undefined,
    ModifiedBy: undefined,
    Name: "",
    CreationDate: "",
    ModificationDate: "",
    VerifyOnUpdate: false,
    Errors: [],
    rowState: 0,
  };
  //#endregion
  //#region state
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    ValidationErrorModel[]
  >([]);
  const [validationSchema, setValidationSchema] = useState(
    Yup.object({
      Name: Yup.string().required(getLabelName("Company Name.missing")),
      DecimalPlace: Yup.number()
        .positive(getLabelName("mustpositive"))
        .integer(getLabelName("mustintegar")),
      Password: Yup.string().required(),
      Mail: Yup.string().email(getLabelName("validemail")),
      RecivedMail: Yup.string().email(getLabelName("validemail")),
      MaxQuantity: Yup.number()
        .positive(getLabelName("mustpositive"))
        .integer(getLabelName("mustintegar")),
      DefaultTaxPercentage: Yup.number()
        .positive(getLabelName("mustpositive"))
        .integer(getLabelName("mustintegar")),
    })
  );
  const [logoImage, setLogoImage] = useState<string | null>(null);
  //#endregion
  //#region function
  const handleFileDrop = async (files: File[]) => {
    setLoading(true);
    try {
      if (files != null && files.length !== 0) {
        files.map((file) => {
          // acceptedFiles.push(file);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            // @ts-ignore
            let  _logoImage: string = reader.result || "";
            _logoImage= _logoImage.replace(/^data:image\/[a-z]+;base64,/, "");
            // @ts-ignore
            setLogoImage(_logoImage);
            setFormikFieldValueByName(formik, "LogoImage64", _logoImage);
          };
          reader.onabort = () => console.log("file reading was aborted");
          reader.onerror = () => console.log("file reading has failed");
          setLoading(false);
        });
      }
    } catch (err) {
      alert(err);
      setLoading(false);
    } finally {
    }
  };
  const handleFileReject = (files: FileRejection[]) => {
    files.map(async (file) => {
      alert(
        `${file.file.name} ${file.errors[0].code}  ${file.errors[0].message}`
      );
    });
  };
  const handleSubmit = async (data: CompanySettingModel) => {
    try {
      setLoading(true);
      // @ts-ignore
      data.rowState = 2;
      data.LogoImageName = "";
      if (logoImage != null) {
        data.LogoImage = logoImage;
      }
      const res = await saveCompanySetting(data);
      if (res != null && res.Errors != null && res.Errors.length !== 0) {
        setValidationErrors(res.Errors);
        setLoading(false);
      } else {
        setValidationErrors([]);
        setLoading(false);
        onComplete(true);
      }
    } catch (err: any) {
      setLoading(false);
      const errors: ValidationErrorModel[] = [
        { MessageAr: err, MessageEn: err },
      ];
      setValidationErrors(errors);
    }
  };
  //#endregion
  //#region formik
  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: validationSchema,
    // enableReinitialize: true,
    onReset: () => {},
    onSubmit: async (values) => {
      await handleSubmit(values);
    },
  });
  //#endregion
  //#region html
  return (
    <>
      {loading && <LoadingBox />}
      {<ErrorValidationBox errors={validationErrors} />}
      <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <Card>
          <Card.Header>
            <span>{getLabelName("CompanySetting")}</span>
          </Card.Header>
          <Card.Body>
            <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
              <TextBox
                labelName={getLabelName("Company Name")}
                inputName={"Name"}
                inputValue={formik.values.Name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorText={formik.errors.Name}
              />
              <TextBox
                labelName={getLabelName("Mobile")}
                inputName={"Phone"}
                inputValue={formik.values.Phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorText={formik.errors.Phone}
              />
              <TextBox
                labelName={getLabelName("Logo")}
                inputName={"TextLogo"}
                inputValue={formik.values.TextLogo}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                errorText={formik.errors.TextLogo}
              />
              <TextBox
                labelName={getLabelName("Address")}
                inputName={"CompanyAddress"}
                errorText={formik.errors.CompanyAddress}
                inputValue={formik.values.CompanyAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextBox
                labelName={getLabelName("Tax Number")}
                inputName={"TaxNumber"}
                errorText={formik.errors.TaxNumber}
                inputValue={formik.values.TaxNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <TextBox
                labelName={getLabelName("Terms and Condations")}
                inputName={"TermsAndCondations"}
                errorText={formik.errors.TermsAndCondations}
                inputValue={formik.values.TermsAndCondations}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="textarea"
              />
            </div>
            <div className="row mt-4 g-3">
              <div className="col-md-4">
                <div className="img-cotainer img-thumbnail">
                <EntityUploader
                    acceptTypes={{
                      allowedMimeTypes: ["image/png", "image/jpg", "image/jpeg"],
                    }}
                    defaultImage={`data:image/jpeg;base64,${formik.values.LogoImage64}`}
                    handleFileDrop={handleFileDrop}
                    handleFileReject={handleFileReject}
                />
                </div>
              </div>
            </div>
          </Card.Body>
          <Card.Header>
            <span>{getLabelName("SystemSetting")}</span>
          </Card.Header>
          <Card.Body>
            <Row className="mb-3">
              <Col xs={6} className="list-group-wrapper">
                <ul className="list-group">
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="SetScaleOnPrice"
                      name="SetScaleOnPrice"
                      checked={formik.values.SetScaleOnPrice}
                      label={getLabelName(
                        "Set Scale Barcode Reading To  Price not Quntity"
                      )}
                    />
                  </li>
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="SetScaleWeightonKG"
                      name="SetScaleWeightonKG"
                      checked={formik.values.SetScaleWeightonKG}
                      label={getLabelName("Set Scale Weight on KG")}
                    />
                  </li>
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="SendMailOfSalesOnClose"
                      name="SendMailOfSalesOnClose"
                      checked={formik.values.SendMailOfSalesOnClose}
                      label={getLabelName(
                        "Send Mail With Sales on Close Application"
                      )}
                    />
                  </li>
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="IsSentEmailReportAfterCloseDayOnly"
                      name="IsSentEmailReportAfterCloseDayOnly"
                      checked={formik.values.IsSentEmailReportAfterCloseDayOnly}
                      label={getLabelName(
                        "Send Report Of Sales After Close Day Only"
                      )}
                    />
                  </li>
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="HideItemFromCloseDay"
                      name="HideItemFromCloseDay"
                      checked={formik.values.HideItemFromCloseDay}
                      label={getLabelName("Remove Item From Report Close Day")}
                    />
                  </li>
                </ul>
              </Col>
              <Col xs={6} className="list-group-wrapper">
                <ul className="list-group">
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="CalcDiscountWithVAT"
                      name="CalcDiscountWithVAT"
                      checked={formik.values.CalcDiscountWithVAT}
                      label={getLabelName("Calc Discount With Vat")}
                    />
                  </li>
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="Is_Company_Authorized_Tobacco"
                      name="Is_Company_Authorized_Tobacco"
                      checked={formik.values.Is_Company_Authorized_Tobacco}
                      label={getLabelName("Is Company Authorized For Tobacco")}
                    />
                  </li>
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="PrintItemArabicEngInBill"
                      name="PrintItemArabicEngInBill"
                      checked={formik.values.PrintItemArabicEngInBill}
                      label={getLabelName("Print Item Name Arabic and English")}
                    />
                  </li>
                  <li className="list-group-item">
                    <Form.Check
                      type="checkbox"
                      onChange={formik.handleChange}
                      id="IncludeVatOnEditPrice"
                      name="IncludeVatOnEditPrice"
                      checked={formik.values.IncludeVatOnEditPrice}
                      label={getLabelName("Include Vat On Edit Price")}
                    />
                  </li>
                </ul>
              </Col>
            </Row>

            <div className="row row-cols-xxl-3 row-cols-xl-3 row-cols-lg-3 row-cols-md-1 row-cols-sm-1 row-cols-1 g-md-4 g-sm-4">
              <TextBox
                labelName={getLabelName("Decimal Place Number")}
                inputName={"DecimalPlace"}
                errorText={formik.errors.DecimalPlace}
                inputValue={formik.values.DecimalPlace}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
              />
              <TextBox
                labelName={getLabelName("CurrentPasswordText")}
                inputName={"DecimalPlace"}
                errorText={formik.errors.Password}
                inputValue={formik.values.Password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="password"
              />
              <TextBox
                labelName={getLabelName("Recived Mail")}
                inputName={"RecivedMail"}
                errorText={formik.errors.RecivedMail}
                inputValue={formik.values.RecivedMail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Sender Mail")}
                inputName={"Mail"}
                errorText={formik.errors.Mail}
                inputValue={formik.values.Mail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
              />
              <TextBox
                labelName={getLabelName("Max Quantity")}
                inputName={"MaxQuantity"}
                errorText={formik.errors.MaxQuantity}
                inputValue={formik.values.MaxQuantity}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
              />
              <TextBox
                labelName={getLabelName("Default Tax Percentage")}
                inputName={"DefaultTaxPercentage"}
                errorText={formik.errors.DefaultTaxPercentage}
                inputValue={formik.values.DefaultTaxPercentage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="number"
              />
            </div>
          </Card.Body>
          <Card.Footer className="d-flex justify-content-end">
            <PrivilegesChecker formId={formId} action="EnableSave">
              <ButtonBox
                type="submit"
                className="mx-2"
                variant="primary"
                iconType="content-save"
              >
                {getLabelName("save")}
              </ButtonBox>
            </PrivilegesChecker>
            <ButtonBox
              iconType="refresh"
              variant="primary"
              type="button"
              onClick={formik.handleReset}
            >
              {getLabelName("reset")}
            </ButtonBox>
          </Card.Footer>
        </Card>
      </form>
    </>
  );
  //#endregion
};
