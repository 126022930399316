import React, {FC} from "react";
import {
    ActionTypeEnum,
    HasFormIdModel,
    TableActionColumnsStyleEnum,
    UserRegistrationResponseModel,
} from "../../models";
import {getLabelName, isArabicCurrentLanguage} from "../../utils";
import {TableColumn} from "react-data-table-component";
import {ButtonBox} from "../common/button/button";
import {GridTable, PrivilegesChecker, TextBox} from "..";

interface UsersListProps extends HasFormIdModel {
  request: UserRegistrationResponseModel[];
  isModifyAble?: boolean | null;
  isDeleteAble?: boolean | null;
  isPermissionAble?: boolean | null;
  onActionEvent?: any | null;
  onCompleteEvent?: any | null;
}

export const UsersList: FC<UsersListProps> = ({
  request,
  isModifyAble = false,
  isDeleteAble = false,
  isPermissionAble = false,
  onActionEvent,
  formId,
}) => {
  //#region variables
  const isArabic: boolean = isArabicCurrentLanguage();
  const generateColumnList =
    (): TableColumn<UserRegistrationResponseModel>[] => {
      return [
        {
          name: "#",
          selector: (_row, index) => Number(index || 0) + 1,
          width: "50px",
        },
        {
          name: (
            <label style={{ width: "fit-content" }}>
              {getLabelName("User Name")}
            </label>
          ),
          selector: (row) => row.User_Name,
          sortable: true,
        },
        {
          name: (
            <label style={{ width: "fit-content" }}>
              {getLabelName("Name")}
            </label>
          ),
          selector: (row) => (isArabic ? row.Name : row.Name_EN),
          sortable: true,
        },
        {
          name: (
            <label style={{ width: "fit-content" }}>
              {getLabelName("isAdmin")}
            </label>
          ),
          cell: (row) => (
            <TextBox
              inputName="IsAdmin"
              inputValue={row.IsAdmin}
              labelName=""
              type={"checkbox"}
            />
          ),
          sortFunction: isUserAdminSort,
        },
        {
          cell: (row) =>
            isPermissionAble && (
              <ButtonBox
                iconType="account-search"
                as="button"
                className="btn btn-outline-primary"
                onClick={() => {
                  onActionEvent({
                    id: row.ID,
                    action: ActionTypeEnum.GrantPermissions,
                  });
                }}
              >
                {getLabelName("Permissions")}
              </ButtonBox>
            ),
          minWidth: "125px",
        },
        {
          cell: (row) =>
            isPermissionAble && (
              <ButtonBox
                iconType="brightness-5"
                as="button"
                className="btn-gradient-primary"
                onClick={() => {
                  onActionEvent({
                    id: row.ID,
                    action: ActionTypeEnum.UserSetting,
                  });
                }}
              >
                {getLabelName("ettings")}
              </ButtonBox>
            ),
          minWidth: "175px",
        },
        {
          cell: (row) =>
            isPermissionAble && (
              <ButtonBox
                iconType="receipt"
                as="button"
                className="btn-gradient-primary"
                onClick={() => {
                  onActionEvent({
                    id: row.ID,
                    action: ActionTypeEnum.TransactionSetting,
                  });
                }}
              >
                {getLabelName("transactionSettings")}
              </ButtonBox>
            ),
          minWidth: "165px",
        },
        {
          cell: (row) =>
            isPermissionAble && (
              <ButtonBox
                iconType="brightness-5"
                as="button"
                className="btn-gradient-primary"
                onClick={() => {
                  onActionEvent({
                    id: row.ID,
                    action: ActionTypeEnum.POSSetting,
                  });
                }}
              >
                {getLabelName("pos Setting")}
              </ButtonBox>
            ),
          minWidth: "175px",
        },
        {
          cell: (row) =>
            isModifyAble && (
              <PrivilegesChecker formId={formId} action="EnableUpdate">
                <ButtonBox
                  iconType="pencil-box"
                  as="button"
                  variant=""
                  className="btn-gradient-primary"
                  onClick={() => {
                    onActionEvent({
                      id: row.ID,
                      action: ActionTypeEnum.Update,
                    });
                  }}
                >
                  {getLabelName("Edit")}
                </ButtonBox>
              </PrivilegesChecker>
            ),
        },
        {
          cell: (row) =>
            isDeleteAble && (
              <PrivilegesChecker formId={formId} action="EnableDelete">
                <ButtonBox
                  iconType="delete"
                  as="button"
                  variant="danger"
                  onClick={() => {
                    //onSelect({ id: row.ID, type: "update" });
                    onActionEvent({
                      id: row.ID,
                      action: ActionTypeEnum.Delete,
                    });
                  }}
                >
                  {getLabelName("delete")}
                </ButtonBox>
              </PrivilegesChecker>
            ),
        },
      ];
    };

  //#endregion
  //#region functions
  const isUserAdminSort = (
    rowA: UserRegistrationResponseModel,
    rowB: UserRegistrationResponseModel
  ) => {
    const a = rowA.IsAdmin;
    const b = rowB.IsAdmin;

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  //#endregion
  //#region html
  return (
    <>
      <GridTable
        columnsProps={{
          actions: [
            ActionTypeEnum.GrantPermissions,
            ActionTypeEnum.UserSetting,
            ActionTypeEnum.TransactionSetting,
            ActionTypeEnum.POSSetting,
            ActionTypeEnum.Update,
            ActionTypeEnum.Delete,
          ],
          isArabic,
          onActionEvent: onActionEvent,
        }}
        formId= {formId}
        data={request}
        totalRows={10000}
        currentPage={1}
        paginationType="none"
        pageSize={10000}
       // style={TableActionColumnsStyleEnum.DropDownText}
       // columns={generateColumnList()}
      />
    </>
  );
  //#endregion
};
